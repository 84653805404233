.c-form-container {

	&__footer,
	&__banner {
		background-color: palette(purple);
		color: $c-white;
		padding: $spacing-base;

		@include breakpoint($bp-medium) {
			padding: $spacing-base $spacing-medium;
		}

		p:not([class]) {
			@extend %sans-serif-base;
			font-weight: $font-weight-normal;
			margin: 0;
		}

		// Spacing on mobile
		.o-flex__item {
			@include breakpoint($bp-xsmall) {
				margin-bottom: $spacing-base;
			}

			&:last-child {
				@include breakpoint($bp-xsmall) {
					margin-bottom: 0;
				}
			}
		}
	}

	&__footer {
		position: relative;
		min-height: toRems(100px);

		&:before {
			animation: spinner 1s infinite linear;
			border-color: rgba($c-white, 0.25) rgba($c-white, 0.25) rgba($c-white, 0.25) $c-white;
			border-radius: 100%;
			border-style: solid;
			border-width: 6px;
			content: "";
			display: block;
			height: toRems(50px);
			left: 50%;
			margin-left: toRems(-25px);
			margin-top: toRems(-25px);
			position: absolute;
			top: 47.5%;
			transition-duration: transform 0.3s $global-easing, opacity 0.3s $global-easing;
			width: toRems(50px);
		}

		&.is-loaded {
			min-height: 0;

			&:before {
				opacity: 0;
			}
		}
	}

	&__title {
		@extend %sans-serif-large;
		margin-bottom: 0;
	}

	&__title-small {
		@extend %sans-serif-mid-large;
		font-weight: $font-weight-bold;
		margin-bottom: 0;
	}

	&__content {
		background-color: $c-white;
		padding: $spacing-medium $spacing-base;

		@include breakpoint($bp-medium) {
			padding: toRems(40px) $spacing-medium;
		}

		> ol > li {
			margin-bottom: $spacing-medium;

			@include breakpoint($bp-medium) {
				margin-bottom: toRems(40px);
			}
		}
	}
}
