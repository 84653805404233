.c-map-container {
	@extend %c-img-frame;
	height: 300px;
	overflow: hidden;
	position: relative;

	@include breakpoint($bp-large) {
		height: auto;
	}

	&__list {
		position: relative;
		transition: opacity 0.3s $global-easing, transform 0.5s $global-easing;

		@include breakpoint($bp-large) {
			@include direction-context($direction: right-to-left) {
				@include span-columns(3.65);
			}

			height: toRems(650px);
			overflow: hidden;
		}

		// Bottom gradient
		&:after {
			background-image: linear-gradient(to top, rgba(palette(grey, light), 1), rgba(palette(grey, light), 0));
			bottom: 0;
			content: "";
			display: none;
			height: toRems(100px);
			left: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			z-index: 10;

			@include breakpoint($bp-large) {
				display: block;
			}
		}
	}

	&__content {
		background-color: transparent;
		height: auto;
		transition: background 1s $global-easing 0s;

		@include breakpoint($bp-large) {
			height: toRems(650px);
			overflow: auto;
			padding-bottom: toRems(100px);
			transform: translate3d(0, 0, 0);
			width: 100%;
		}

		&.is-loading {
			@extend %c-img-frame;
			background-color: $c-white;
			height: toRems(200px);

			&:after {
				content: "Loading locations..."
			}
		}
	}

	&__frame {
		display: none;

		@include breakpoint($bp-medium) {
			display: block;
			margin-bottom: $spacing-medium;
			opacity: 1;
			position: relative;
			transition: opacity 0.3s $global-easing, transform 0.5s $global-easing;
		}

		@include breakpoint($bp-large) {
			@include direction-context($direction: right-to-left) {
				@include omega;
				@include span-columns(8.35);
			}

			height: toRems(600px);
			margin-bottom: 0;
		}
	}

	&__popup {
		@extend %c-img-frame;
		@include material-shadow(2);
		background-color: palette(purple);
		height: 150px;
		left: 50%;
		opacity: 0;
		position: absolute;
		top: calc(50% - 50px);
		transform: translate(-50%, -50%) scale(1.1);
		transition: opacity 0.3s $global-easing, visibility 0.3s $global-easing, transform 0.5s $global-easing;
		visibility: hidden;
		width: 300px;
		z-index: 100;

		&:before {
			border-color: rgba($c-white, 0.25) rgba($c-white, 0.25) rgba($c-white, 0.25) $c-white;
			top: 42%;
		}

		&:after {
			@extend %sans-serif-base;
			color: $c-white;
			top: calc(42% + 35px);
		}
	}

	&.is-loaded {
		height: auto;

		&:before,
		&:after {
			display: none;
		}

		.c-map-container__list,
		.c-map-container__frame {
			opacity: 1;
			transform: none;
		}
	}

	&.is-reloading {
		.c-map-container__popup {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
			visibility: visible;
		}

		.c-map-container__list,
		.c-map-container__frame {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
