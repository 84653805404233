.s-cms-content {
  @include clearfix;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: $spacing-small;

    @include breakpoint($bp-medium) {
      margin-bottom: $spacing-base;
    }
  }

  // Headings
  h1:not([class]) {
    @extend %sans-serif-xlarge;
  }

  h2:not([class]) {
    @extend %sans-serif-large;
  }

  h3:not([class]) {
    @extend %sans-serif-mid-large;
  }

  h4:not([class]) {
    @extend %sans-serif-medium;
  }

  h5:not([class]) {
    @extend %sans-serif-base;
  }

  h6:not([class]) {
    font-size: toRems($base-font-size);
    font-weight: $font-weight-normal;
    line-height: 1.4;
  }

  a:not([class]) {
    border-bottom: 1px solid rgba(palette(purple, light), 0.5);
    color: $c-link;
    font-weight: $font-weight-bold;
    text-decoration: none;

    &:hover {
      color: $c-link-hover;
      border-bottom-color: palette(purple);
    }

    // &:visited,
    // &:active {
    //  color: $c-link-visited;
    //  border-bottom: 1px solid;
    // }
  }

  // Do not wrap phone numbers
  a[href^="tel:"] {
      white-space: nowrap;
  }

  // Images
  figure {
    margin-bottom: $spacing-medium;

    img {
      width: 100%;
    }

    & + .c-table {
      margin-top: $spacing-medium;
    }

    &:not([c-img-frame]) {
      margin-bottom: 0;
    }
  }

  // Image Captions
  figcaption {
    background: #ccc;
    padding: $spacing-small $spacing-base;
  }

  blockquote {
    & + .c-table {
      margin-top: $spacing-medium;
    }
  }

  cite {

  }

  a {
    word-break: break-word;
  }

  p {
    margin-bottom: $spacing-small;
    margin-top: 0;

    &:last-of-type:last-child {
      margin-bottom: 0;
    }

    & + .c-table {
      margin-top: $spacing-medium;
    }
  }

  strong {
    font-weight: $font-weight-bold;
  }

  ul:not([class]) {
    @extend %c-list;
    @extend %c-list--unordered;
  }

  ol:not([class]) {
    @extend %c-list;
    @extend %c-list--ordered;
  }

  // Arabic - bullets are set on the right
  &:lang(ar) {
    ul:not([class]) {
      @extend %c-list;
      @extend %c-list--unordered;
      @extend %c-list--reverse;
    }

    ol:not([class]) {
      @extend %c-list;
      @extend %c-list--ordered;
      @extend %c-list--reverse;
    }
  }

  blockquote:not([class]) {
    @extend %sans-serif-base;
    color: palette(purple);
    margin: $spacing-base 0 $spacing-small;
    quotes: "\201C""\201D""\2018""\2019";

    @include breakpoint($bp-medium) {
      font-size: toRems(21px);
    }

    &:before,
    &:after {
      color: palette(purple, light);
      font-size: $font-size-2;
      line-height: toRems($base-font-size);
    }

    &:before {
      content: open-quote;
      margin-right: 7px;
      vertical-align: -0.2em;
    }

    &:after {
      content: close-quote;
      margin-left: 5px;
      vertical-align: -0.2em;
    }
  }

  &--no-bullets {
    ul:not([class]),
    ol:not([class]) {
      li {
        padding-left: 0;
        
        &:before {
          content: '';
        }
      }
    }
  }

  .c-tab__content-inner & {
    li {
      font-weight: $font-weight-normal;
    }

    h4 {
      font-size: toRems(20px);
      font-weight: $font-weight-semibold;
    }

    p {
      &:last-of-type:last-child {
        margin-bottom: $spacing-base;
      }
    }
  }
}
