.c-form-radio {

	&__input {
		left: -9999em;
		position: absolute;

		// Keyboard focus
		html[data-focus-source="key"] > body &:focus {
			& + .c-form-radio__label {
				background-color: palette(a11y);
				color: $c-black;
				outline: 5px solid palette(a11y);
			}
		}

		// Checked state
		&:checked {
			& + .c-form-radio__label:after {
				background: palette(purple, light);
			}
		}
	}

	&__label {
		cursor: pointer;
		font-size: $font-small;
		letter-spacing: 0;
		line-height: 2em;
		padding-left: 35px;
		padding-right: 20px;
		position: relative;
		text-transform: none;

		&:before {
			background: $c-white;
			border-radius: 20px;
			border: 1px solid rgba(palette(grey), 0.25);
			content: '.';
			cursor: pointer;
			height: 20px;
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 20px;
		}

		&:after {
			border-radius: 20px;
			content: '.';
			cursor: pointer;
			height: 10px;
			left: 5px;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 10px;
		}
	}

	&.parsley-error {
        & ~ .c-form-error {
            display: block;
			margin-top: $spacing-base;

			// Hide triangle
			&:before {
				display: none;
			}
        }
    }

	&--btn {

		.c-form-radio__input {
			// Keyboard focus
			html[data-focus-source="key"] > body &:focus {
				& + .c-form-radio__label {
					background: palette(purple);
					color: $c-white;
				}
			}

			// Checked state
			&:checked {
				& + .c-form-radio__label {
					background: palette(purple);
					color: $c-white;
				}
			}

			// Disabled state
			&:disabled {
				& + .c-form-radio__label {
					opacity: 0.5;
					cursor: not-allowed;
					text-decoration: line-through;

					// Disable hover
					&:hover {
						background-color: palette(purple, x-light);
					}
				}

				&:checked {
					& + .c-form-radio__label {
						// Disable checked hover
						&:hover {
							background-color: palette(purple);
						}
					}
				}
			}
		}

		.c-form-radio__label {
			@extend %sans-serif-base;
			background-color: palette(purple, x-light);
			color: $c-font;
			display: block;
			font-weight: $font-weight-normal;
			min-height: 50px;
			padding: toRems(15px) $spacing-small;
			text-align: center;
			transition: all 0.3s $global-easing;
			width: 100%;

			&:before,
			&:after {
				display: none;
			}

			&:hover {
				background-color: tint(palette(purple), 60%);
			}
		}
	}
}
