.c-form-select {
    background-color: $c-white;
    border: 1px solid $c-white;
    color: $c-font;
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    max-height: 60px;
    transition: border 0.5s $global-easing, opacity 0.5s $global-easing;

    &:before {
        background-color: palette(purple, light);
        content: "";
        display: block;
        height: toRems(40px);
        pointer-events: none;
        position: absolute;
        right: toRems(10px);
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s $global-easing;
        width: toRems(40px);
    }

    &:after {
        @extend %i-chevron-thick-down;
        color: $c-white;
        display: block;
        font-size: toRems(18px);
        line-height: 1;
        pointer-events: none;
        position: absolute;
        right: toRems(21px);
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover,
    &.is-focused {
        &:before {
            background-color: palette(purple);
        }
    }

    &.is-focused {
        border-color: palette(purple, light) !important;
    }

    &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &__input {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        color: $c-font;
        cursor: pointer;
        font-size: toRems($base-font-size);
        font-weight: $font-weight-bold;
        line-height: 1;
        min-height: toRems(60px);
        padding: toRems(19px) toRems(60px) toRems(19px) $spacing-base;
        position: relative;
        width: 100%;
        z-index: 1;

        &.is-disabled {
			opacity: 0.3;
			pointer-events: none;
		}
    }

    // Hide native arrow in IE
    select::-ms-expand {
        display: none;
    }

    &.parsley-error {
        border-color: palette(errors);

        & + .c-form-error {
            display: block;
        }
    }

    &--simple {
        &:before {
            display: none;
        }

        &:after {
            color: palette(purple);
            right: toRems(18px);
        }

        .c-form-select__input {
            padding-right: toRems(35px);
        }
    }

    &--light-purple {
        background-color: palette(purple, x-light);
        border-color: palette(purple, x-light);
    }

    &--purple {
        background-color: palette(purple);
        border-color: palette(purple);

        .c-form-select__input {
            color: $c-white;

			option {
				background-color: palette(purple);
	            color: $c-white;
			}
        }

        &:hover {
            &:before {
                background-color: $c-white;
            }

            &:after {
                color: palette(purple, light);
            }
        }

        // >= IE10
        _:-ms-input-placeholder, :root & {
            &:before,
            &:after {
                z-index: 2;
            }

            .c-form-select__input {
                background-color: palette(purple);
            }
        }
    }

    &--compact {
        @include breakpoint($bp-medium) {
            margin: 0 auto;
            max-width: toRems(500px);
        }
    }
}
