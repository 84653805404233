.c-location-info {
  border-bottom: 1px solid palette(grey, mid-light);

  &__features-list {
    display: flex;
    margin: 14px 0 20px;
  }

  &__features-item {
    display: flex;
    height: 24px;
    margin-right: 20px;
  }

  &__price {
    @extend %sans-serif-mid-large;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__address {
    @extend %sans-serif-base;
    font-style: unset;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 20px;
  }

}

.location-features-icon {
  margin-right: 8px;
  width: 22px;
}
