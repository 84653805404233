.u-bg-white {
	background-color: $c-white;
}

.u-bg-grey {
	background-color: palette(grey);
}

.u-bg-grey-light {
	background-color: palette(grey, light);
}

.u-bg-purple {
	background-color: palette(purple);
}
