/**
 * Generated SVG Sprite Sizes
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more icons to the svg spritesheets:
 *    1. Add svgs to "images/svg/sprites/"
 *    2. Run `gulp svg-sprites` in your terminal
 *
 */

%svg-icon {
	display: inline-block;
}

.svg-icon-back-to-top {
	@extend %svg-icon;
	height: 26px;
	width: 26px;
}

.svg-icon-chevron-down {
	@extend %svg-icon;
	height: 9px;
	width: 14px;
}

.svg-icon-chevron-left {
	@extend %svg-icon;
	height: 14px;
	width: 9px;
}

.svg-icon-chevron-right {
	@extend %svg-icon;
	height: 14px;
	width: 9px;
}

.svg-icon-chevron-thick-down {
	@extend %svg-icon;
	height: 11px;
	width: 17px;
}

.svg-icon-chevron-thick-left {
	@extend %svg-icon;
	height: 17px;
	width: 11px;
}

.svg-icon-chevron-thick-right {
	@extend %svg-icon;
	height: 17px;
	width: 11px;
}

.svg-icon-chevron-thick-up {
	@extend %svg-icon;
	height: 11px;
	width: 17px;
}

.svg-icon-chevron-up {
	@extend %svg-icon;
	height: 9px;
	width: 14px;
}

.svg-icon-close {
	@extend %svg-icon;
	height: 25px;
	width: 25px;
}

.svg-icon-facebook {
	@extend %svg-icon;
	height: 20px;
	width: 20px;
}

.svg-icon-globe {
	@extend %svg-icon;
	height: 24px;
	width: 24px;
}

.svg-icon-google-plus {
	@extend %svg-icon;
	height: 18px;
	width: 28px;
}

.svg-icon-instagram {
	@extend %svg-icon;
	height: 20px;
	width: 20px;
}

.svg-icon-linkedin {
	@extend %svg-icon;
	height: 20px;
	width: 20px;
}

.svg-icon-location {
	@extend %svg-icon;
	height: 25px;
	width: 17px;
}

.svg-icon-magnifier {
	@extend %svg-icon;
	height: 25px;
	width: 25px;
}

.svg-icon-mail {
	@extend %svg-icon;
	height: 16px;
	width: 24px;
}

.svg-icon-phone {
	@extend %svg-icon;
	height: 43px;
	width: 43px;
}

.svg-icon-pin {
	@extend %svg-icon;
	height: 45px;
	width: 30px;
}

.svg-icon-pinterest {
	@extend %svg-icon;
	height: 20px;
	width: 20px;
}

.svg-icon-print {
	@extend %svg-icon;
	height: 22px;
	width: 24px;
}

.svg-icon-share {
	@extend %svg-icon;
	height: 22px;
	width: 22px;
}

.svg-icon-twitter {
	@extend %svg-icon;
	height: 18px;
	width: 23px;
}

.svg-icon-youtube {
	@extend %svg-icon;
	height: 20px;
	width: 20px;
}

