.c-form-text,
%c-form-text {
	background-color: $c-white;
	opacity: 1;
	transition: opacity 0.5s $global-easing;
	position: relative;
	z-index: 1;

	&__label {
		border-bottom: 1px solid palette(grey, mid-light);
		color: $c-font;
		font-weight: $font-weight-bold;
		left: $spacing-base;
		line-height: 1.1;
		position: absolute;
		top: toRems(22px);
		transition: all 0.3s $global-easing;
		z-index: -1;

		@include breakpoint($bp-xsmall) {
			white-space: nowrap;
		}
	}

	&__input {
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		border-radius: 0;
		border: 1px solid $c-white;
		color: $c-font;
		display: block;
		font-size: toRems($base-font-size);
		min-height: toRems(60px);
		padding: toRems(31px) $spacing-xsmall toRems(7px);
		text-overflow: ellipsis;
		width: 100%;

		@include placeholder {
			color: $c-white;
			font-size: 0;
		}

		&:focus {
			border-color: palette(purple, light) !important;
		}

		&:disabled {
			opacity: 1;
		}

		&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 toRems(1000px) $c-white inset;
		}
	}

	textarea.c-form-text__input {
		background-color: transparent;
		min-height: toRems(200px);
		resize: vertical;
	}

	.parsley-errors-list {
		@include clearfix;

		li {
			@extend %c-form-error;
		}
	}

	&.parsley-error {
		.c-form-text__input {
			border-color: palette(errors);
		}

		.parsley-errors-list li {
			display: block;
		}
	}

	&.is-active {
		&:before {
			opacity: 1;
			transform: none;
			transition: all 0.5s $global-easing 0.2s;
		}

		.c-form-text__label {
			border: none;
			color: palette(purple);
			left: $spacing-xsmall;
			top: $spacing-xsmall;
			z-index: 1;
		}
	}

	&.is-disabled {
		opacity: 0.3;
		pointer-events: none;
	}

	&--purple {
		background-color: palette(purple);

		.c-form-text__label {
			color: $c-white !important;
			border-bottom: 1px solid rgba($c-white, 0.25);
		}

		.c-form-text__input {
	        border-color: palette(purple);
			color: $c-white;

			@include placeholder {
				color: palette(purple);
			}

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 toRems(1000px) palette(purple) inset;
			}
		}
	}

	&--light-purple {
		background-color: palette(purple, x-light);

		.c-form-text__input {
	        border-color: palette(purple, x-light);

			@include placeholder {
				color: palette(purple, x-light);
			}

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 toRems(1000px) palette(purple, x-light) inset;
			}
		}
	}

	&--dollar {
		&:before {
			color: rgba($c-font, 0.7);
			content: "$";
			font-size: toRems($base-font-size);
			left: $spacing-xsmall;
			opacity: 0;
			position: absolute;
			top: $spacing-medium;
			transform: translateY(-5px);
			transition: all 0.3s $global-easing;
		}

		.c-form-text__input {
			padding-left: toRems(25px);
		}
	}
}
