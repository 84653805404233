.c-email-signup {
	background-color: palette(grey, light);
	padding: $spacing-medium 0;

	@media print {
		display: none;
	}

	@include breakpoint($bp-small) {
		padding: toRems(40px) 0;
	}

	&__inner {
		max-width: toRems(600px);
		margin: 0 auto;

		@include breakpoint($bp-medium) {
			display: table;
			max-width: none;
			width: 100%;
		}
	}

	&__item {
		@include breakpoint($bp-medium) {
			display: table-cell;
			vertical-align: middle;
		}

		&:first-child {
			@include breakpoint($bp-medium) {
				width: 70%;
			}
		}

		&:last-child {
			@include breakpoint($bp-medium) {
				text-align: right;
				width: 30%;
			}
		}
	}

	&__title {
		@extend %sans-serif-large;

		@include breakpoint($bp-medium) {
			margin: 0;
		}
	}
}
