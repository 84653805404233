.c-btn-expand {
	font-weight: $font-weight-bold;
	padding-right: $spacing-medium;
	position: relative;

	&__inner {
		border-bottom: 1px solid rgba($c-white, 0.5);
		position: relative;
	}

	&__icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%) translateZ(0);
		transition: transform 0.3s $global-easing;

		svg {
			display: inline-block;
			fill: $c-white;
			height: 18px;
			vertical-align: middle;
			width: 18px;
		}
	}
}
