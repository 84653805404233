.o-visual-grid {
	@include clearfix;
	display: block;
	margin: $spacing-small 0;
	position: relative;

	@include breakpoint($bp-medium) {
		margin-left: (-(flex-gutter() / 2));
		margin-right: (-(flex-gutter() / 2));
	}

	// If it's the first item in the container, remove top margin
	&:first-child:first-of-type {
		margin-top: 0;
	}

	// If it's the last item in the container, remove bottom margin
	&:last-child:last-of-type {
		margin-bottom: 0;
	}

	&__item {
		margin-bottom: $spacing-small;

		@include breakpoint($bp-medium) {
			display: inline-block;
			float: left;
			margin-bottom: 0;
			padding: flex-gutter() / 2;
			width: 50%;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.is-full-width {
			@include breakpoint($bp-medium) {
				width: 100%;
			}
		}
	}
}
