.c-card,
%c-card {
  background-color: $c-white;
  color: $c-font;
  display: block;
  padding: $spacing-base;
  position: relative;
  transition: background-color 0.5s $global-easing, color 0.5s $global-easing;

  @include breakpoint($bp-small) {
    padding-bottom: $spacing-base * 3.5;
  }

  @media print {
    height: auto !important;
    padding: 0;
  }

  &__link {
    color: $c-font;

    &:hover {
      .c-card__title {
        color: palette(purple);

        span {
          // Animate underline border
          &:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &__inner {
    padding: $spacing-base 0;

    @media print {
      padding: 0 !important;
    }
  }

  &__title {
    @extend %sans-serif-mid-large;
    line-height: 1.2;
    margin-bottom: $spacing-small;

    a,
    span {
      @include c-text-link;

      &:hover {
        color: palette(purple);
      }
    }
  }

  &__subtitle {
    @extend %sans-serif-base;
    margin-bottom: $spacing-small;
  }

  &__body {
    margin-bottom: toRems(25px);
  }

  &__spacer {
    height: toRems(0);
    // @include breakpoint($bp-xsmal) {
    //   height: 0;
    // }

    @include breakpoint($bp-small) {
      height: toRems(50px);
    }
  }

  &__list {
    li {
      margin-bottom: $spacing-base;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__action {
    @include linear-gradient(
      to bottom,
      rgba(palette(pink), 0) 0%,
      palette(pink) 50%
    );
    bottom: 0;
    height: toRems(100px);
    padding: $spacing-base;
    position: absolute;
    right: 0;
    text-align: right;
    transition: background 0.3s $global-easing;
    visibility: hidden;
    width: 100%;
    z-index: 10;

    .c-btn-expand {
      position: absolute;
      bottom: $spacing-base;
      right: $spacing-base;
    }
  }

  // Modifiers
  &--no-pad {
    padding: 0;
  }

  &--pad-content {
    .c-card__inner {
      padding-bottom: $spacing-small;
    }
  }

  &--green,
  &--purple {
    a:not([class]),
    .s-cms-content a:not([class]) {
      color: $c-white;

      &:hover,
      &:focus {
        border-color: $c-white;
        color: $c-white;
      }
    }

    .c-card__title {
      color: $c-white;

      span {
        color: $c-white;
      }

      a:before,
      span:before {
        background-color: $c-white;
      }
    }

    .c-card__header {
      padding-top: 0;
    }

    .c-card__inner {
      color: $c-white;
      padding: $spacing-base;

      @include breakpoint($bp-large) {
        padding: $spacing-medium $spacing-base toRems(40px);
      }
    }
  }

  &--green {
    background-color: palette(green);
    color: $c-white;

    a, a:hover {
      color: $c-white;
    }

    a:before {
      background-color: $c-white;
    }
  }

  &--purple {
    background-color: palette(purple);
    color: $c-white;

    a, a:hover {
      color: $c-white;
    }

    a:before {
      background-color: $c-white;
    }
  }

  &--pink {
    background-color: palette(pink);
    color: $c-white;

    a, a:hover {
      color: $c-white;
    }

    a:before {
      background-color: $c-white;
    }
  }

  &--violet {
    background-color: palette(violet);
    color: $c-white;

    a, a:hover {
      color: $c-white;
    }

    a:before {
      background-color: $c-white;
    }
  }

  &--orange {
    background-color: palette(orange);
    color: $c-white;

    a, a:hover {
      color: $c-white;
    }

    a:before {
      background-color: $c-white;
    }
  }

  &--feature {
    border-bottom: 1px solid rgba($c-black, 0.1);

    .c-card__inner {
      padding: $spacing-base 0;
    }

    .c-card__link {
      @include breakpoint($bp-xsmall) {
        color: $c-white;
      }

      &:hover {
        .c-card__title {
          @include breakpoint($bp-xsmall) {
            color: $c-white;
          }

          span:before {
            @include breakpoint($bp-xsmall) {
              background-color: $c-white;
            }
          }
        }
      }
    }

    .c-card__title {
      @include breakpoint($bp-large) {
        font-size: $font-size-2;
      }

      span:hover {
        @include breakpoint($bp-xsmall) {
          color: $c-white;
        }
      }
    }
  }

  &--title-only\@xsmall {
    @include breakpoint($bp-xsmall) {
      background-color: palette(purple);
    }

    .c-card__title {
      @include breakpoint($bp-xsmall) {
        margin: 0;

        a {
          color: $c-white;

          &:before {
            background-color: $c-white;
          }
        }
      }
    }

    .c-card__inner {
      @include breakpoint($bp-xsmall) {
        padding: $spacing-base;
      }
    }

    [class*='c-btn'],
    [class*='c-link'],
    .c-img-frame,
    .c-card__body,
    .c-video {
      @include breakpoint($bp-xsmall) {
        display: none;
      }
    }
  }

  &--notification {
    background: palette(purple);
    color: $c-white;
  }

  &--align-cta {
    @include breakpoint($bp-medium) {
      padding-bottom: $spacing-base + toRems(50px);
    }

    .c-btn {
      @include breakpoint($bp-medium) {
        bottom: $spacing-base;
        left: $spacing-base;
        position: absolute;
      }
    }
  }

  &--simple {
    .c-card__inner {
      padding: 0;
    }

    .c-card__title {
      margin-bottom: $spacing-xsmall;
    }

    .c-card__body {
      margin-bottom: $spacing-base;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.c-card--pink,
    &.c-card--green,
    &.c-card--violet,
    &.c-card--orange {
      a:not([class]) {
        color: rgba($c-white, 0.9);
        border-color: rgba($c-white, 0.5);

        &:hover {
          color: $c-white;
          border-color: $c-white;
        }
      }
    }
  }

  &.is-cropped {
    height: toRems(300px);
    overflow: hidden;

    .c-card__action {
      visibility: visible;
    }
  }

  &.is-expanded {
    height: auto;

    .c-card__inner {
      padding-bottom: toRems(50px);
    }

    .c-card__action {
      background: transparent;
      height: 0;
    }

    .c-btn-expand__icon {
      transform: translateY(-50%) rotate(180deg) translateZ(0);
    }
  }

  .c-btn {
    position: relative;
    left: 0;

    @include breakpoint($bp-small) {
      position: absolute;
      bottom: $spacing-base * 2;
      left: $spacing-base;
    }
  }

  &--podcast {
    padding-bottom: inherit;

    .c-card__title {
      color: palette(purple);
    }

    .c-btn {
      position: static;
      margin: $spacing-base 0;
    }
  }
}

a.c-card {
  &:hover {
    background-color: palette(purple);
    color: $c-white;
  }
}
