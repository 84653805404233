.o-inline-group {
	// font-size: 0;

	&__item {
		display: inline-block;
		float: none;
		margin-right: $spacing-base;
		width: auto;

		@include breakpoint($bp-medium) {
			margin-right: $spacing-medium;
		}

		&:last-child {
			margin-right: 0 !important;
		}

		strong {
			white-space: nowrap;
		}
	}

	&--zero {
		.o-inline-group__item {
			margin: 0 !important;
		}
	}

	&--tight {
		.o-inline-group__item {
			margin-right: $spacing-xxsmall;
			margin-bottom: $spacing-xsmall;
		}
	}

	&--inline\@medium {
		.o-inline-group__item {
			display: block;
			margin-bottom: $spacing-base;
			margin-right: 0;

			@include breakpoint($bp-medium) {
				display: inline-block;
				margin-bottom: 0;
				margin-right: $spacing-small;
			}

			@include breakpoint($bp-xlarge) {
				margin-right: $spacing-medium;
			}
		}
	}
}
