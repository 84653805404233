.c-text-dropdown {
	display: block;
	min-height: toRems(60px);
	position: relative;

	&:before {
        background-color: palette(purple, light);
        content: "";
        display: block;
        height: toRems(40px);
        pointer-events: none;
        position: absolute;
        right: toRems(10px);
        bottom: toRems(10px);
        transition: all 0.5s $global-easing;
        width: toRems(40px);

		.no-touchevents & {
			display: none;
		}
    }

    &:after {
        @extend %i-chevron-thick-down;
        color: $c-white;
        display: block;
        font-size: toRems(18px);
        line-height: 1;
        pointer-events: none;
        position: absolute;
        right: toRems(21px);
        bottom: toRems(20px);

		.no-touchevents & {
			display: none;
		}
    }

	&__label {
		@extend %c-form-label;
	}

	> select {
		@extend %c-form-label;
		-moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
		background-color: $c-white;
        background-image: none;
        border: none;
		border-radius: 0;
        box-shadow: none;
        color: $c-font;
        cursor: pointer;
		font-size: toRems($base-font-size);
        font-weight: $font-weight-bold;
		height: toRems(60px);
		display: block;
		margin: 0;
		padding: $spacing-base toRems(80px) $spacing-base $spacing-base;
		width: 100%;

		// Hide on desktop to prevent flash of unstyled dropdown
		.no-touchevents & {
			display: none;
		}
	}

	.parsley-errors-list {
		display: none;
	}

	&.has-error {
		.chosen-container {
			.chosen-single {
				border-color: palette(red);

				&:before {
					background-color: palette(red);
				}
			}
		}
		.parsley-errors-list {
			display: block;
		}
	}

	.chosen-container {
		position: relative;

		// The default state, showing the currently selected option
		.chosen-single {
			background: $c-white;
			border: 1px solid $c-white;
			border-radius: 0;
			box-shadow: none;
			color: $c-font;
			cursor: pointer;
			display: block;
			font-size: toRems($base-font-size);
			font-weight: $font-weight-bold;
			height: auto;
			line-height: 1.125;
			padding: $spacing-base toRems(80px) $spacing-base $spacing-base;
			position: relative;

			&:before {
				background-color: palette(purple, light);
				content: "";
				display: block;
				height: toRems(40px);
				pointer-events: none;
				position: absolute;
				right: toRems(10px);
				top: 50%;
				transform: translateY(-50%);
				transition: all 0.5s $global-easing;
				width: toRems(40px);
			}

			&:after {
				@extend %i-chevron-thick-down;
				color: $c-white;
				display: block;
				font-size: toRems(18px);
				line-height: 1;
				pointer-events: none;
				position: absolute;
				right: toRems(21px);
				top: 50%;
				transform: translateY(-50%);
			}

			> div {
				// Junk elements used for chevron
				display: none;
			}
		}

		// Dropdown active
		&.chosen-with-drop {
			z-index: 10;

			.chosen-single {
				background: $c-white;
				border-color: $c-white;
				box-shadow: none;
			}
		}

		// Dropdown panel
		.chosen-drop {
			background: $c-white;
			border: none;
			border-radius: 0;
			box-shadow: none;

			.group-result {
				@extend %c-form-label;
				margin-bottom: 0;
				margin-top: $spacing-xsmall;

				&:first-child {
					margin-top: 0;
				}
			}

			.group-option {
				@extend %sans-serif-base;
				font-size: toRems(16px);
				font-weight: $font-weight-normal;

				// Currently selected / hovered
				&.highlighted {
					background-color: palette(green);
					color: $c-white;
				}
			}

			.disabled-result {
				display: none;
			}
		}
	}

}
