.c-form-checkbox {

	&__input {
		left: -9999em;
		position: absolute;

		// Keyboard focus
		html[data-focus-source="key"] > body &:focus {
			& + .c-form-checkbox__label {
				background-color: palette(a11y);
				color: $c-black;
				outline: 5px solid palette(a11y);
			}
		}

		// Checked state
		&:checked {
			& + .c-form-checkbox__label:before {
				background-color: palette(purple, light);
				border-color: palette(purple, light);
			}
		}
	}

	&__label.c-form-checkbox__label {
		color: $c-font;
		cursor: pointer;
		display: block;
		font-size: toEms(14px);
		font-weight: normal;
		letter-spacing: 0;
		letter-spacing: 0;
		line-height: 1.6em;
		padding-left: 35px;
		position: relative;
		text-transform: none;

		&:before {
			background: $c-white;
			border-radius: 0;
			border: 1px solid rgba(palette(grey), 0.25);
			content: '.';
			cursor: pointer;
			height: 20px;
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 20px;
		}

		&:after {
			@extend %i-tick;
			color: $c-white;
			display: block;
			font-size: 12px;
			left: 5px;
			margin-top: -6px;
			position: absolute;
			top: 50%;
		}
	}

	& + .parsley-errors-list {
		@include clearfix;
		margin-top: $spacing-base;

		li {
			@extend %c-form-error;

			// Hide triangle
			&:before {
				display: none;
			}
		}
	}

	&.parsley-error {
        & + .parsley-errors-list li {
            display: block;
        }
    }
}
