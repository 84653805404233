/**
 * Generated Icons
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This icons Sass file is generated by Gulp
 * To add more icons to the icon font:
 *    1. Add svgs to "images/icons/"
 *    2. Run `gulp icons` in your terminal
 *
 */

@font-face {
	font-family: "uniting-agewell";
	src: url("/fonts/uniting-agewell.eot");
	src: url("/fonts/uniting-agewell.eot?#iefix") format("embedded-opentype"),
		url("/fonts/uniting-agewell.woff") format("woff"),
		url("/fonts/uniting-agewell.ttf") format("truetype"),
		url("/fonts/uniting-agewell.svg#uniting-agewell") format("svg");
	font-style:  normal;
    font-weight:  normal;
}

%font-icon,
[class^="i-"],
[class*=" i-"] {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: "uniting-agewell";
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-decoration: none;
	text-transform: none;
}

$icons: 'chevron-down', 'chevron-left', 'chevron-right', 'chevron-thick-down', 'chevron-thick-left', 'chevron-thick-right', 'chevron-thick-up', 'chevron-up', 'pinterest', 'tick';

.i-chevron-down:before,
%i-chevron-down {
	@extend %font-icon;
	content: "\E001";
}

.i-chevron-left:before,
%i-chevron-left {
	@extend %font-icon;
	content: "\E002";
}

.i-chevron-right:before,
%i-chevron-right {
	@extend %font-icon;
	content: "\E003";
}

.i-chevron-thick-down:before,
%i-chevron-thick-down {
	@extend %font-icon;
	content: "\E004";
}

.i-chevron-thick-left:before,
%i-chevron-thick-left {
	@extend %font-icon;
	content: "\E005";
}

.i-chevron-thick-right:before,
%i-chevron-thick-right {
	@extend %font-icon;
	content: "\E006";
}

.i-chevron-thick-up:before,
%i-chevron-thick-up {
	@extend %font-icon;
	content: "\E007";
}

.i-chevron-up:before,
%i-chevron-up {
	@extend %font-icon;
	content: "\E008";
}

.i-pinterest:before,
%i-pinterest {
	@extend %font-icon;
	content: "\E009";
}

.i-tick:before,
%i-tick {
	@extend %font-icon;
	content: "\E00A";
}

