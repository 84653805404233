.c-split-header {
	font-size: 0;
	margin-bottom: $spacing-base;

	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-medium;
	}

	&__item {
		display: block;
		margin-bottom: $spacing-base;
		width: 100%;

		@include breakpoint($bp-small) {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
			width: 50%;
		}

		&:last-child {
			margin-bottom: 0 !important;
			text-align: left;

			@include breakpoint($bp-small) {
				text-align: right;
			}
		}
	}

	&__title {
		@extend %sans-serif-large;
		margin: 0;
    max-width: 320px;
    
    &--single {
      max-width: unset;
      text-align: left;
    }
	}
}
