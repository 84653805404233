.c-img-frame,
%c-img-frame {
	background-color: palette(grey, light);
	overflow: hidden;
	position: relative;
	z-index: 1;

	@media print {
		display: none;
	}

	&:before {
		animation: spinner 1s infinite linear;
		border-color: rgba($c-black, 0.25) rgba($c-black, 0.25) rgba($c-black, 0.25) rgba($c-black, 0.75);
		border-radius: 100%;
		border-style: solid;
		border-width: 6px;
		content: "";
		display: block;
		height: toRems(50px);
		left: 50%;
		margin-left: toRems(-25px);
		margin-top: toRems(-25px);
		position: absolute;
		top: 47.5%;
		transition-duration: transform 0.3s $global-easing, opacity 0.3s $global-easing;
		width: toRems(50px);
		z-index: -1;
	}

	&:after {
		animation: flashing 1.2s infinite $global-easing;
		color: $c-font;
		content: "Loading...";
		font-weight: $font-weight-bold;
		left: 0;
		padding-left: 10px;
		position: absolute;
		text-align: center;
		top: calc(47.5% + 35px);
		width: 100%;
		z-index: -1;
	}

	img {
		width: 100%;
	}

	&--small {
		&:before {
			height: toRems(36px);
			margin-left: toRems(-18px);
			margin-top: toRems(-18px);
			width: toRems(36px);
			border-width: 5px;
		}

		&:after {
			display: none;
		}
	}

	&--background {
		@include breakpoint($bp-large) {
			height: 100%;
		}

		.c-img-frame__background {
			@include breakpoint($bp-large) {
				background-position: center center;
				background-size: cover;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
}
