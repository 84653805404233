.o-podcast-nav {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-medium;

    a {
        display: flex;
        gap: 15px;
        align-items: center;
        color: palette(grey, mid-light);
        line-height: 1.2;

        &:hover svg {
            transform: scale(1.5);
        }
    }

    strong {
        display: block;
        font-weight: 700;
        font-size: 20px;
        color: palette(purple);
    }

    svg {
        width: 15px;
        height: 25px;
        fill: palette(purple);
        transition: transform 100ms ease-in-out;
    }
}

.o-podcast-nav__next {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
}