.o-listing-group {
	&__item {
		margin-bottom: $spacing-small;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	&--small {
		.o-listing-group__item	{
			margin-bottom: $spacing-xxsmall;
		}
	}

	&--large {
		.o-listing-group__item	{
			margin-bottom: $spacing-base;

			@include breakpoint($bp-medium) {
				margin-bottom: $spacing-medium;
			}
		}
	}
}
