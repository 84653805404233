.c-link {
  color: $c-link;
  display: inline-block;
  line-height: 1.4;
  position: relative;
  text-decoration: none;
  transition: none;

  &:hover {
    .c-link__inner {
      border-color: palette(purple);
    }
  }

  &__inner {
    border-bottom: 1px solid rgba(palette(purple, light), 0.5);
    position: relative;
    transition: all 0.5s $global-easing;
  }

  &--social {
    padding: $spacing-xsmall $spacing-small $spacing-xsmall toRems(50px);
    transition: all 0.5s $global-easing;

    svg {
      fill: $c-white;
      fill: currentColor;
      left: toRems(10px);
      margin-top: toRems(-10px);
      max-height: toRems(20px);
      max-width: toRems(20px);
      position: absolute;
      top: 50%;
      transition: all 0.5s $global-easing;
    }

    &.is-twitter {
      &:hover,
      &:focus {
        background-color: $c-twitter;
      }
    }

    &.is-facebook {
      &:hover,
      &:focus {
        background-color: $c-facebook;
      }
    }

    &.is-instagram {
      z-index: 1;

      &:after {
        background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAYEBAQFBAYFBQYJBgUGCQsIBgYICwwKCgsKCgwQDAwMDAwMEAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwBBwcHDQwNGBAQGBQODg4UFA4ODg4UEQwMDAwMEREMDAwMDAwRDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDP/AABEIADIAoAMBEQACEQEDEQH/xABqAAADAQEBAAAAAAAAAAAAAAAAAQIDBAYQAAICAgMBAQEBAAAAAAAAAAABAhEEYQMSgRMUIZEBAQEBAQEBAQEAAAAAAAAAAAIBAwAEBgUHEQEBAAMBAQEBAAAAAAAAAAAAAQIDEhMEERT/2gAMAwEAAhEDEQA/APKd2ffXN6J8592Ds584UmG5tJ859mG7Gk+Y1JhuxpPmNNhuxrPlO2H0az5jsPo0nzC2d6HPnO2T0aT5xbJ6FNAtndnNAO7LwBe1mkF7XyOi9u8hRe3eQpl7TzCQu082kEXsbrcKQLsfmzSpRBdjWaTUTO7GuOlXUF2NppNRDdjSaToN2NJpOg+jSaT6k9Cmo+pPQ5qOjvQvIdTvRfMdS+i+Yo70XzFF7d5nQu3eYovacChdp5ii9pwaQpmlwXBF6C4ORRMLseLHUpQM7sbY6jUQXY1mpSgC7Gs1moh9Gk1n1D6HNY6k9CmB9SehTAUd2XB0d2vAo7teBRe3cCi9u4FF7dwKFM05FCmbuRQpknIHM05FCmQ3BpAvQXFmuE/Ny3vPMFrhZld7WYKXAzO72kwP4MF3tJifxJ7nMR8Se5TEvkzvYpiXyL7FyXzZfVeS6Cm1eS6sXovIovbuQLt3IFMk5Bek5FDmScgUyTkhzIfwGkyS4riLoLi744uj5fL6WMxWsXRjfpORaxdGd+k5D/LoH9JQfl0T+koTxtHf0kTxtFn0FEPG0OfQSHj6HPoVD4NGk3kzlwmuO5UPjZrjtVDg0azY78S0aTN34VDmSfgHMk/D/opR/AOZD+ChzIbFRHMgselji6PgMt7CLWLoyu9VLF0C71/Vfl0H3X9H5dE9y6J4ui+69JeLos3F0zeNoc3r+s5Y2jSbymTOWNo1x3lKxnj6NsdxSsZ8BvjuL9Yz4T0Y7l/WMuM9GO0mbib47HIaNpkn4RpKlhjlCwIco2LgOULHso+H82yeRa8M65a8DVNeBcf+HKT8Och+ChIl4KFGUvDSFGU/DSFGE/DbE4wn4ejE455+HoxKMJnqwVzzPVgTJnpxclm2KEjSDTHAq4DgV//Z);
        background-position: 50% 50%;
        background-size: cover;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.5s $global-easing;
        z-index: -1;
      }

      &:hover,
      &:focus {
        &:after {
          opacity: 1;
        }
      }
    }

    &.is-youtube {
      &:hover,
      &:focus {
        background-color: $c-youtube;
      }
    }
  }

  &--chevron {
    color: $c-font;
    transition: transform 0.3s $global-easing;

    &:before {
      @extend %i-chevron-right;
      color: palette(purple);
      font-size: 12px;
      font-weight: $font-weight-bold;
      left: toRems(-20px);
      opacity: 0;
      padding: toRems(5px) 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.3s $global-easing;
      width: toRems(20px);
    }

    &:hover {
      color: palette(purple);
      transform: translateX(toRems(20px));
      &:before {
        opacity: 1;
        pointer-events: all;
      }

      .c-link__inner {
        border-color: transparent;
      }
    }

    .c-link__inner {
      border-color: transparent;
    }
  }

  &--white {
    color: $c-white;

    .c-link__inner {
      border-color: rgba($c-white, 0.33);
    }

    &:hover {
      .c-link__inner {
        border-color: $c-white;
      }
    }
  }

  &--social-tight {
    padding: $spacing-xsmall $spacing-small $spacing-xsmall toRems(40px);
  }

  &--white-hover {
    &:hover,
    &:focus {
      color: $c-white;

      .c-link__inner {
        border-color: $c-white;
      }
    }
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--animated {
    .c-link__inner {
      @include c-text-link;
      border-bottom: none;

      &:before {
        height: toRems(2px);
      }
    }

    &:hover {
      .c-link__inner {
        // Animate underline border
        &:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}
