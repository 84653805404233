.js-lazyload {
	opacity: 0;
	transform: scale(1.1);
	transition: opacity 0.3s $global-easing, transform 1s $global-easing;

	&.js-lazyloaded {
		opacity: 1;
		transform: none;
	}

	&.js-lazyload-error {
		min-height: 200px;
		opacity: 1;
		outline: 1px solid red;

		&:before {
			content: "Error";
			color: red;
		}
	}

	@media print {
		display: none;
	}
}
