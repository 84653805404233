.c-form-error,
%c-form-error {
	@include clearfix;
	background-color: palette(errors);
	color: $c-white;
	display: none;
	font-weight: $font-weight-bold;
	margin: 0;
	max-height: toRems(60px);
	padding: toRems(7px) $spacing-base;
	position: relative;
	text-align: center;
	z-index: 1;

	&:before {
		background-color: palette(red);
		content: "";
		height: 20px;
		left: 50%;
		position: absolute;
		top: 0;
		transform: rotate(45deg) translate(-50%, 0);
		width: 20px;
		z-index: -1;
	}

	li {
		margin: 0;
	}

	&.is-visible {
		display: block;
	}

	&--block {
		display: block;
		margin-bottom: $spacing-base;

		&:before {
			display: none;
		}
	}

	&--compact {
		@include breakpoint($bp-medium) {
			margin: 0 auto;
			max-width: toRems(500px);
		}
	}
}

ul.errors {
  margin-top: toRems(20px);
  color: red;
}