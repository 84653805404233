.c-section-heading {
	@extend %sans-serif-large;
	margin-bottom: $spacing-medium;
	max-width: toRems(960px);

	@include breakpoint($bp-medium) {
		margin: 0 auto $spacing-medium;
		text-align: center;
	}

	&--align-left {
		text-align: left;

		@include breakpoint($bp-medium) {
			margin: 0 0 $spacing-medium;
		}
	}
}
