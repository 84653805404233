.c-panel {
	@include clearfix;
	background-color: $c-white;
	padding: $spacing-base;

	@include breakpoint($bp-xsmall) {
		padding: $spacing-small;
	}

	@media print {
		padding: 0;
	}

	&:last-child {
		margin-right: 0;
	}

  &__wrapper {
    margin-top: $spacing-base;
    overflow: hidden;
  }

	&__img {
		display: table-header-group;
		width: 100%;

		.c-img-frame {
			margin-bottom: $spacing-base;

			@include breakpoint($bp-medium) {
				margin-bottom: 0;
			}
		}

		@include breakpoint($bp-medium) {
			@include span-columns(4 of 8);
			display: block;
		}

		@include breakpoint($bp-large) {
			@include span-columns(3.5 of 8);
			margin-bottom: 0;
		}

		@media print {
			display: none !important;
		}
	}

	&__title {
		@extend %sans-serif-mid-large;
		line-height: 1.1;
		margin-bottom: $spacing-xsmall;

		a:not([class]) {
			@include c-text-link;

			&:hover {
				color: palette(purple);
			}
		}
	}

	&__body {
		margin-bottom: $spacing-base;
	}

	&__content {
		display: table-footer-group;

		@include breakpoint($bp-medium) {
			@include span-columns(4 of 8);
			display: block;
		}

		@include breakpoint($bp-large) {
			@include span-columns(4.5 of 8);
		}

		@media print {
			width: 100% !important;
		}
	}

	&--rtl {
		.c-panel__content {
			@include breakpoint($bp-medium) {
				@include omega;
				float: right;
			}
		}
	}
}
