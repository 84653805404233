.u-max-720 {
	&#{&} {
		max-width: 720px;
	}
}

.u-max-960 {
	&#{&} {
		max-width: 960px;
	}
}
