.c-list,
%c-list  {
	list-style-type: none;
	margin-bottom: $spacing-medium;
	padding: 0;
	position: relative;

	li {
		margin-bottom: $spacing-small;
		padding-left: $spacing-medium;
		position: relative;

		&:before {
			color: palette(purple);
			display: inline-block;
			left: 0;
			line-height: 1;
			position: absolute;
			top: 0;
			vertical-align: middle;
		}
	}

	// Unordered Lists
	&--unordered,
	%c-list--unordered {
		li:before {
			content: ".";
			font-size: toRems(40px);
			line-height: 10px;
			top: -0.11em;
		}
	}

	// Ordered Lists
	&--ordered,
	%c-list--ordered {
		counter-reset: section;

		li:before {
			content: counter(section,decimal)".";
			counter-increment: section;
			font-weight: $font-weight-semibold;
			left: 0;
			top: 0.08em;
		}

		ol {
			li {
				&:before {
					content: counter(section);
				}
			}
		}
	}

	// Set bullet/number points on the right
	// Used for different languages
	&--reverse {
		li {
			padding-right: $spacing-medium;
			padding-left: 0;

			&:before {
				right: 0;
			}
		}
	}
}
