.o-split-row {
	@include clearfix;
	margin-bottom: $spacing-base;

	&:last-child {
		margin-bottom: 0;
	}

	// Only built for 6 tiles max
	&__item {
		margin-bottom: $spacing-base;

		@include breakpoint($bp-medium) {
			@include span-columns(4 of 8);
			@include omega(2n);
		}

		&:first-child {
			@include breakpoint($bp-large) {
				@include span-columns(5.5);
			}
		}

		&:nth-child(2) {
			@include breakpoint($bp-large) {
				@include span-columns(6.5);
				@include omega;
			}
		}

		&:nth-child(3) {
			@include breakpoint($bp-large) {
				@include span-columns(6.5);
			}
		}

		&:nth-child(4) {
			@include breakpoint($bp-large) {
				@include span-columns(5.5);
				@include omega;
			}
		}

		&:nth-child(5) {
			@include breakpoint($bp-large) {
				@include span-columns(5.5);
			}
		}

		&:nth-child(6) {
			@include breakpoint($bp-large) {
				@include span-columns(6.5);
				@include omega;
			}
		}

		// Last two children no margin @ large
		&:nth-last-child(-n+2) {
			@include breakpoint($bp-large) {
				margin-bottom: 0;
			}
		}

		@media print {
			display: block !important;
			width: 100% !important;
		}
	}
}
