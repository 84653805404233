.c-pagination {
	@extend %sans-serif-base;
	text-align: center;

	&__item {
		display: inline-block;

		a,
		b {
			@extend %c-btn;
			background-color: palette(grey, light);
			color: palette(purple);
			font-weight: $font-weight-semibold;
			min-width: toRems(44px);
			padding-left: $spacing-base;
			padding-right: $spacing-base;

			svg {
				fill: palette(purple);
				height: toRems(16px);
				margin-top: toRems(-8px);
				width: toRems(16px);
			}
		}

		a {
			&:hover,
			html[data-focus-source="key"] > body &:focus {
				background-color: palette(purple);
				color: $c-white;

				svg {
					fill: $c-white;
				}

				.c-btn__inner {
					color: $c-white;
				}
			}
		}

		b {
			background-color: palette(green);
			color: $c-white;
		}
	}
}
