.u-1\/1 {
	@include span-columns(12);
}

.u-1\/2 {
	@include span-columns(6);
}

.u-1\/4 {
	@include span-columns(3);
}

.u-3\/4 {
	@include span-columns(9);
}

.u-1\/3 {
	@include span-columns(4);
}

.u-2\/3 {
	@include span-columns(8);
}

/**
 * @bp-medium
 */

.u-1\/2\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(6);
    }
}

.u-1\/4\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(3);
    }
}

.u-3\/4\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(9);
    }
}

.u-1\/3\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(4);
    }
}

.u-2\/3\@medium {
	@include breakpoint($bp-medium) {
        @include span-columns(8);
    }
}

/**
 * @bp-large
 */

.u-1\/2\@large {
    @include breakpoint($bp-large) {
        @include span-columns(6);
    }
}

.u-1\/4\@large {
    @include breakpoint($bp-large) {
        @include span-columns(3);
    }
}

.u-3\/4\@large {
    @include breakpoint($bp-large) {
        @include span-columns(9);
    }
}

.u-1\/3\@large {
    @include breakpoint($bp-large) {
        @include span-columns(4);
    }
}

.u-2\/3\@large {
    @include breakpoint($bp-large) {
        @include span-columns(4);
    }
}

.u-5\/12\@xlarge {
	@include breakpoint($bp-xlarge) {
        @include span-columns(5);
    }
}

// Shifts
.u-shift-1\@xlarge {
	@include breakpoint($bp-xlarge) {
        @include shift-in-context(1);
    }
}

.u-grid-last.u-grid-last {
	@include omega;
}
