.c-form-custom-error {
	display: block;

	.parsley-errors-list {
		@extend %c-form-error;

		&.filled {
			display: block;
			margin-bottom: $spacing-base;

			&:before {
				left: 7%;
			}
		}
	}
}
