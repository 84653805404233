.o-podcast-cta {
    text-align: center;
    color: white;

    h2 {
        margin-bottom: 0;
    }
}

.o-podcast-cta__actions {
    display: flex;
    justify-content: center;
    gap: $spacing-medium;
}