// MixItUp Styling

.js-filter .js-filter-item {
    .js & {
        display: none;
    }
}

.js-filter-error {
    display: none;
}
