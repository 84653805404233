.c-location-availability {
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint($bp-medium) {
      flex-direction: row;
    }
  }

  &__heading {
    @include breakpoint($bp-medium) {
      margin-right: 40px;
      min-width: 50%;
    }
  }

  &__subheading {
    @extend %sans-serif-mid-large;
    font-weight: 600;
  }

  &__listing {
    display: flex;
    flex-direction: column;

    @include breakpoint($bp-medium) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    margin-bottom: 20px;

    @include breakpoint($bp-medium) {
      width: 49%;
    }
  }
}
