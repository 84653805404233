.c-btn-distance {
	@extend %sans-serif-base;
	background-color: palette(purple, light);
	border-radius: 20px;
	color: $c-white;
	display: inline-block;
	font-size: toRems($base-font-size);
	font-weight: $font-weight-semibold;
	line-height: 1.2;
	margin-bottom: $spacing-small;
	margin-left: -5px;
	padding: toRems(9px) $spacing-small toRems(7px) toRems(35px);
	position: relative;
	white-space: nowrap;

	&__icon {
		fill: $c-white;
		height: toRems(20px);
		left: $spacing-small;
		margin-top: toRems(-10px);
		position: absolute;
		top: 50%;
		width: toRems(13px);
	}

	em {
		font-style: normal;

		&:before {
			content: open-quote;
		}

		&:after {
			content: close-quote;
		}
	}
}
