.o-site-container {
	padding-top: toRems(60px);

	@include breakpoint($bp-medium) {
		padding-top: toRems(210px);
	}

	@include breakpoint($bp-large) {
		padding-top: toRems(115px);
	}

	&__inner {
		position: relative;
	}

	.js-nav-open & {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
	}
}

#recite-wrapper .o-site-container img {
	background: none !important;
}
