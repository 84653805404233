.c-map {
	position: relative;

	&:after {
		content: "";
		display: block;
		height: 0;
		padding-bottom: 66.67%;
	}

	@include breakpoint($bp-large) {
		position: absolute !important;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}

	&__iframe {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}

	&__item {
		font-family: $base-font-family;
		font-size: $font-small;
		padding: $spacing-xsmall $spacing-xxsmall;
		line-height: 1.3;

		p {
			margin-bottom: 0;
		}
	}

	&__item-title {
		@extend %sans-serif-mid-large;
		line-height: 1;
		margin-bottom: $spacing-xxsmall;
	}

	&__item-subtitle {
		font-size: toRems($base-font-size);
	}

	&__item-body {
		padding-top: $spacing-base;
	}
}
