.c-video {
	display: block;
	margin: 0;
	padding: 0;
	position: relative;

	&:after {
		background-color: $c-black;
		bottom: 0;
		content: "";
		left: 0;
		opacity: 0.6;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}

	&__button {
		background-color: palette(purple);
		border: solid 5px $c-white;
		border-radius: 100%;
		content: "";
		height: 70px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%) scale(1);
		transition: background-color 0.5s $global-easing,;
		width: 70px;
		z-index: 10;

		// Play icon
		&:after {
			border-color: transparent transparent transparent $c-white;
			border-style: solid;
			border-width: 14px 0 14px 20px;
			content: "";
			height: 0;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-30%, -50%);
			width: 0;
			z-index: 10;
		}

	}

	&__label {
		font-weight: $font-weight-bold;
		color: $c-white;
		display: block;
		font-size: $font-size-4;
		position: relative;
    left: 50%;
    text-align: center;
    top: 70px;
    transform: translateX(-50%);
    width: 200%;
	}


	&:hover,
	&:focus {
		.c-video__button {
			background-color: palette(purple, light);
		}
	}

	&--absolute {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}
