.c-modal {
	background-color: $c-white;
	margin: 0 auto;
	padding: $spacing-large $spacing-base $spacing-large;
	text-align: center;

	@include breakpoint($bp-medium) {
		padding: $spacing-large;
		max-width: toRems(600px);
	}
}

.mfp-wrap {
	min-width: toRems(320px);

	.c-btn-close {
		position: absolute;
		right: 0;
		top: 0;

		@include breakpoint($bp-medium) {
			right: $spacing-base;
			top: $spacing-base;
		}
	}
}

.mfp-container {
	padding-top: $spacing-xlarge;
}

.mfp-content {
	padding: $spacing-medium $spacing-xsmall;
	vertical-align: top;

	@include breakpoint($bp-medium) {
		padding: 0;
		vertical-align: middle;
	}
}

// Custom magnific popup animation
.mfp-rise-in-out {
    // Overlay initial state
    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s $global-easing;
        // Overlay animate in
        &.mfp-ready {
            opacity: 0.8;
        }
        // Overlay animate out
        &.mfp-removing {
            opacity: 0;
        }
    }

    &.mfp-wrap {
        // Content initial state
        .mfp-content {
            opacity: 0;
            transform: translateY(100px);
        }

        .c-btn-close {
            opacity: 0;
            transform: rotate(-90deg);
            transition: all 0.5s $global-easing;
            z-index: 9999;
        }

        // Content animate in
        &.mfp-ready {
            .mfp-content {
                opacity: 1;
                transform: none;
                transition: all 0.87s $global-easing 0.2s;
            }

            .c-btn-close {
                opacity: 1;
                transform: rotate(0deg);
            }
        }
        // Content animate out
        &.mfp-removing {
            .mfp-content {
                opacity: 0;
                transform: translateY(-100px);
                transition: all 0.5s $global-easing;
            }

            .c-btn-close {
                opacity: 0;
                transform: rotate(-90deg);
            }
        }
    }
}
