.c-inline-form {

	@include breakpoint($bp-medium) {
		display: table;
		width: 100%;
	}

	&__item {
		float: none;
		width: auto;

		@include breakpoint($bp-medium) {
			display: table-cell;
			vertical-align: middle;
			padding: 0 toRems(10px);
		}

		&:last-child {
			@include breakpoint($bp-medium) {
				padding-right: 0;
				width: toRems(170px);
			}
		}
	}
}


.newsletter-captcha {
	width: toRems(232px);
	padding: toRems(31px) toRems(10px) 0 0;
	transform:scale(0.77);
	-webkit-transform:scale(0.75);
	transform-origin:0 0;
	-webkit-transform-origin:0 0;
}
