.c-text-link {
  @include c-text-link;
  color: palette(purple);
  font-size: toRems($base-font-size);
  line-height: 1;
  border: none;

  &__icon {
    display: inline-block;
    height: toRems(16px);
    margin-right: $spacing-xxsmall;
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: toRems(16px);
  }

  &--white {
    color: $c-white;

    &:before {
      background-color: rgba($c-white, 0.5);
    }
  }

  &--small-caps\@medium {
    color: $c-font;

    @include breakpoint($bp-medium) {
      font-size: $font-small;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
    }
  }

  &--large {
    font-family: $proxima-nova;
    font-size: toRems(24px);
    font-weight: $font-weight-black;
    line-height: 1.3;

    @include breakpoint($bp-xlarge) {
      font-size: $font-size-3;
    }

    &:before {
      bottom: 0;
    }
  }

  &--no-bold {
    font-weight: $font-weight-normal;
  }

  &--pink {
      color: palette(pink);

      svg {
        fill: palette(pink);
      }
  }
   
}
