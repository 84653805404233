.c-listing-container {
    opacity: 1;
    transition: opacity 0.3s $global-easing;

    &__loader {
        @extend %c-img-frame;
        @include material-shadow(2);
        background-color: palette(purple);
        height: 150px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50px;
        transform: translate(-50%, 0) scale(1.1);
        transition: opacity 0.3s $global-easing, visibility 0s $global-easing 0.5s, transform 0.3s $global-easing;
        visibility: hidden;
        width: 300px;
        z-index: 100;

        &:before {
            border-color: rgba($c-white, 0.25) rgba($c-white, 0.25) rgba($c-white, 0.25) $c-white;
            top: 42%;
        }

        &:after {
            @extend %sans-serif-base;
            color: $c-white;
            margin-top: 35px;
            top: 42%;
        }
    }

    &.is-loading {
        .c-listing-container__loader {
            opacity: 1;
            transform: translate(-50%, 0) scale(1);
            transition: opacity 0.3s $global-easing, visibility 0s $global-easing 0s, transform 0.3s $global-easing;
            visibility: visible;
        }

        .o-listing-group {
            opacity: 0.5;
        }
    }
}
