.c-drawer {
  // On hover/keyboard focus show content
  &.is-active {
    .c-drawer__head {
      &:before {
        opacity: 1;
        transform: none;
      }
    }

    // First mouse enter
    .c-drawer__head + .c-drawer__content {
      opacity: 1;
      transform: none;
      transition: opacity 0.5s $global-easing, visibility 0.5s $global-easing,
        transform 1s $global-easing;
      visibility: visible;
    }
  }

  &__head {
    align-items: center;
    background-color: palette(purple);
    color: $c-white;
    cursor: pointer;
    display: flex;
    height: toRems(92px);
    min-height: toRems(44px);
    padding: $spacing-base;
    position: relative;
    text-align: left;
    transition: background-color 0.5s $global-easing;
    width: 100%;

    // Cascade purple background on mobile
    @for $i from 1 through 10 {
      .o-inline-group__item:nth-child(#{$i + 1}) & {
        background-color: rgba(palette(purple), (1 - (0.15 * $i)));
      }
    }
    @include breakpoint($bp-medium) {
      text-align: center;
      background-color: transparent !important;
      padding: $spacing-xsmall $spacing-base $spacing-base;
    }

    html[data-focus-source='key'] > body &:focus {
      background-color: palette(a11y) !important;

      &:before {
        opacity: 1;
        transform: none;
      }
    }

    &:hover {
      @include breakpoint($bp-medium-max) {
        background-color: palette(green) !important;
      }

      &:before {
        opacity: 1;
        transform: none;
      }
    }

    &:before {
      background-color: palette(purple);
      bottom: 0;
      content: '';
      display: block;
      height: toRems(4px);
      left: 0;
      opacity: 0;
      position: absolute;
      transform: translateY(4px);
      transition: all 0.3s $global-easing;
      width: 100%;

      @include breakpoint($bp-medium-max) {
        display: none;
      }
    }
  }

  &__title {
    @extend %sans-serif-base;
    color: $c-white;
    margin: 0;
    .recite-ele {
      font-size: toRems(12px);
    }
    

    @include breakpoint($bp-medium) {
      color: $c-font;
    }
  }

  &__content {
    display: none;
    font-size: toRems($base-font-size);
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 100%;
    transition: all 0.3s $global-easing; // Mouse leave
    visibility: hidden;
    width: auto;
    z-index: $z-header + 300;

    @include breakpoint($bp-medium) {
      background-color: $c-white;
      display: block;
      top: calc(100% - #{toRems(102px)});
    }

    @include breakpoint($bp-large) {
      top: 100%;
    }

    // When hovering over menu items not first mouse enter
    .js-nav-drawer-active & {
      transform: none !important;
      transition: all 1s $global-easing !important;
    }
  }

  &__inner {
    border-top: 2px solid palette(purple);
    padding: $spacing-xsmall 0;

    @include breakpoint($bp-medium) {
      display: table;
      width: 100%;
    }

    @include breakpoint($bp-large) {
      padding: 0;
    }
  }

  &__column {
    @include breakpoint($bp-medium) {
      display: table-cell;
      padding-left: $spacing-base;
      padding-right: $spacing-base;
      vertical-align: top;
    }

    @include breakpoint($bp-large) {
      padding-left: $spacing-medium;
      padding-right: $spacing-medium;
    }

    &:not(:first-child):not(:last-child) {
      padding-bottom: $spacing-small;
      padding-top: $spacing-small;

      @include breakpoint($bp-large) {
        padding-bottom: toRems(25px);
        padding-top: toRems(25px);
      }
    }

    &:first-child {
      background-color: palette(purple);
      color: $c-white;
      padding: 0;

      @include breakpoint($bp-medium) {
        width: 25%;
      }

      @include breakpoint($bp-xlarge) {
        width: 20%;
      }
    }

    &:last-child {
      padding: 0;

      @include breakpoint($bp-medium) {
        width: 25%;
      }

      @include breakpoint($bp-xlarge) {
        width: 20%;
      }
    }
  }

  &__button-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    & a {
      width: 100%;
    }
  }
}

:lang(en) .c-header__nav-container {
  .c-drawer__title {
    .recite-ele {
      font-size: toRems(16px);
    }
  }  
}