.c-footer {
	background-color: palette(purple);
	color: $c-white;
	padding-top: $spacing-medium;

	@media print {
		display: none;
	}

	@include breakpoint($bp-medium) {
		padding-top: toRems(60px);
	}

	a {
		color: $c-white;
	}

	.o-grid {
		@include breakpoint($bp-medium-max) {
			display: table;
			width: 100%;
		}
	}

	&__inner {
		@include clearfix;
		padding-bottom: $spacing-medium;
	}

	&__contact {
		@include clearfix;
		display: table-header-group;

		@include breakpoint($bp-medium) {
			margin-top: -$spacing-medium;
			align-items: flex-end;
			display: flex;
			padding-bottom: $spacing-large;
		}
	}

	&__contact-item {
		@include breakpoint($bp-medium) {
			flex: 0 1 auto;
			width: 33.33%;
		}

		&:last-child {
			@include breakpoint($bp-medium) {
				padding-left: $spacing-base;
				width: 66.66%;
			}

			@include breakpoint($bp-large) {
				padding-left: 6.5%;
			}

			@include breakpoint($bp-xlarge) {
				padding-left: 10.6%;
			}
		}
	}

	&__item {
		margin-bottom: $spacing-medium;

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint($bp-medium) {
			@include span-columns(4);
			margin-bottom: 0;
		}

		&:first-child {
			@include breakpoint($bp-medium) {
				@include span-columns(4);
			}
		}

		&:nth-child(2) {
			@include breakpoint($bp-medium) {
				padding-left: $spacing-base;
			}

			@include breakpoint($bp-large) {
				@include shift-in-context(0.5);
			}

			@include breakpoint($bp-xlarge) {
				@include shift-in-context(1);
			}
		}

		&:last-child {

			@include breakpoint($bp-medium) {
				@include span-columns(4);
				text-align: right;
			}

			@include breakpoint($bp-large) {
				@include span-columns(3.5);
			}

			@include breakpoint($bp-xlarge) {
				@include span-columns(3);
			}
		}
	}

	&__title {
		@extend %sans-serif-large;
		margin-bottom: $spacing-medium;
	}

	&__social {
		padding-top: toRems(40px);
		text-align: left;

		@include breakpoint($bp-medium) {
			padding-left: 33.33%;
		}
	}

	&__bar {
		border-top: 1px solid rgba($c-white, 0.25);
		padding: $spacing-base 0;

		@include breakpoint($bp-medium) {
			padding: $spacing-small 0;
		}

		li {
			margin-bottom: $spacing-xxsmall;

			@include breakpoint($bp-medium) {
				display: inline-block;
				margin: 0 $spacing-base;
			}

			@include breakpoint($bp-large) {
				margin: 0 $spacing-medium;
			}

			&:first-child {
				@include breakpoint($bp-medium) {
					margin-left: 0;
				}
			}

			&:last-child {
				margin-top: $spacing-medium;

				@include breakpoint($bp-medium) {
					float: right !important;
					margin-top: 0;
					text-align: center;
				}

				@include breakpoint($bp-large) {
					@include span-columns(3.5);
				}

				@include breakpoint($bp-xlarge) {
					@include span-columns(3);
				}
			}
		}
	}
}
