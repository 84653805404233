.c-gallery {
	@include clearfix;
	background-color: $c-white;
	display: table;
	width: 100%;

	@include breakpoint($bp-medium) {
		display: block;
	}

	&__content {
		display: table-footer-group;

		@include breakpoint($bp-medium) {
			@include span-columns(4);
			display: block;
			padding-bottom: toRems(70px);
		}
	}

	&__img {
		display: table-header-group;
		overflow: hidden;

		@include breakpoint($bp-medium) {
			@include span-columns(8);
			display: block;
		}

		img {
			width: 100%;
		}
	}

	&__title {
		@extend %sans-serif-mid-large;
		line-height: 1.2;
	}

	&__title-small {
		@extend %sans-serif-base;
		font-size: toRems($base-font-size);
		border-bottom: 1px solid palette(purple, light);
		color: palette(purple);
		display: inline-block;
		padding-top: $spacing-small;

		@include breakpoint($bp-medium) {
			padding-top: 0;
		}
	}
}
