.c-share {
    @include clearfix;
    background-color: $c-white;
    line-height: 1;
    margin-bottom: $spacing-base;

    @include breakpoint($bp-small) {
        margin-left: -$spacing-xxsmall;
    }

    @include breakpoint($bp-medium) {
        margin-left: 0;
    }

    @include breakpoint($bp-xxlarge) {
        padding: $spacing-xxsmall;
        margin-left: 0;
    }

    &__btn {
		background-color: palette(grey, dark);
		display: block;
        color: palette(purple);
        float: left;
        font-weight: $font-weight-bold;
        position: relative;
        z-index: 10;

        @include breakpoint($bp-xxlarge) {
            float: none;
        }

        @include breakpoint($bp-xsmall) {
            display: none;
        }

        &:hover {
            .c-btn-square {
                background-color: palette(green);
            }
        }

        > span {
            display: block;
            margin-bottom: $spacing-xxsmall;
        }

        .c-btn-square {
            background-color: palette(purple);
        }
    }

    &__item {
        float: left;
        line-height: 0;
        margin-bottom: $spacing-xxsmall;
        margin-left: $spacing-xxsmall;
        padding: 0;
        transition: opacity 0.5s $global-easing, transform 0.7s $global-easing;

        @include breakpoint($bp-xxlarge) {
            float: none;
            margin-left: 0;

            .js & {
                opacity: 0;
                transform: translateY(-20px);
            }
        }

        &:first-child {
            @include breakpoint($bp-xsmall) {
                margin-left: 0 !important;
            }
        }

        &:last-child {
            @include breakpoint($bp-xxlarge) {
                margin-bottom: 0;
            }
        }
    }

    &__drawer {
        float: left;
        padding-top: toRems(21px);
        transition: opacity 0.7s $global-easing, visibility 0.7s $global-easing, max-height 0.5s $global-easing;

        @include breakpoint($bp-xxlarge) {
            padding-top: 0;
            float: none;

            .js & {
                opacity: 0;
                visibility: hidden;
                max-height: 0;
            }
        }

        @include breakpoint($bp-xsmall) {
            padding-top: 0;
        }
    }

    &.is-open {

        .c-share__btn {
            .c-btn-square {
                @include breakpoint($bp-medium) {
                    background-color: palette(green);
                }
            }
        }

        .c-share__drawer {
            max-height: toRems(500px);
            opacity: 1;
            visibility: visible;
        }

        .c-share__item {
            opacity: 1;
            transform: translateY(0);

            @for $i from 1 through 7 {
                &:nth-child(#{$i + 1}) {
                    transition-delay: 0.1s * $i;
                }
            }
        }
    }

    &--spacing-top\@large {
        @include breakpoint($bp-xxlarge) {
            margin-top: toRems(35px);
        }
    }
}
