// Left floating image

.u-img-1\/1 {
	&#{&} {
		margin-bottom: $spacing-base;
		width: 100%;
	}
}

.u-img-1\/2-left {
	&#{&} {
		@include span-columns(4 of 8);
		float: left;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-xsmall) {
			@include fill-parent;
		}
	}
}

.u-img-1\/3-left {
	&#{&} {
		@include span-columns(4);
		float: left;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-xsmall) {
			@include fill-parent;
		}
	}
}

.u-img-1\/4-left {
	&#{&} {
		@include span-columns(3);
		float: left;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-xsmall) {
			@include fill-parent;
		}
	}
}
// Right floating image
@include direction-context(right-to-left) {
	.u-img-1\/2-right {
		&#{&} {
			@include span-columns(6);
			float: right;
			margin-bottom: $spacing-base;

			@include breakpoint($bp-xsmall) {
				@include fill-parent;
			}
		}
	}

	.u-img-1\/3-right {
		&#{&} {
			@include span-columns(4);
			float: right;
			margin-bottom: $spacing-base;

			@include breakpoint($bp-xsmall) {
				@include fill-parent;
			}
		}
	}

	.u-img-1\/4-right {
		&#{&} {
			@include span-columns(3);
			float: right;
			margin-bottom: $spacing-base;

			@include breakpoint($bp-xsmall) {
				@include fill-parent;
			}
		}
	}
}
