// Colour palletes
$palettes: (
    color: (
        x-dark: #444,
        dark: #444,
        mid-dark: #444,
        base: #444,
        mid-light: #444,
        light: #444,
        x-light: #444,
		xx-light: #444
    ),
    grey: (
        base: #434345,
        mid-light: #d0d0d0,
        light: #f0f1f2
    ),
    purple: (
        base: #5e56a5,
        light: #8d88c2,
        x-light: #efeef6
    ),
    green: (
        base: #7abc4c,
        mid-light: #7bbc49
    ),
    blue: (
        base: #3970b7
    ),
    red: (
        base: #ee392b
    ),
    violet: (
        base: #9e509f
    ),
    pink: (
        base: #c61d65
    ),
    orange: (
        base: #f2a366
    ),
	a11y: (
		base: #FFBF47
	),
    errors: (
        base: #ee392b
    ),
    border: (
        base: #d6d8da
    )
);

// Global colors
$c-white: #ffffff;
$c-black: #000000;
$c-link: palette(purple);
$c-link-hover: palette(purple);
$c-link-visited: palette(purple, light);
$c-font: palette(grey);
$c-placeholder: $c-font;
$c-border: $c-white;

// Text highlight colors
$text-highlight-color: $c-white;
$text-highlight-bg-color: palette(green);
$tap-highlight-color: rgba(255,255,255, 0);

// Social colors
$c-facebook: #3b5998;
$c-google-plus: #dd4b39;
$c-instagram: #DC3274;
$c-linked-in: #007bb5;
$c-pinterest: #cb2027;
$c-twitter: #00aced;
$c-youtube: #bb0000;
