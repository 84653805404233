.c-btn-close,
%c-btn-close {
	display: inline-block;
	height: 60px;
	position: relative;
	width: 60px;

	svg {
		fill: palette(purple);
		height: toRems(24px);
		left: 50%;
		margin-left: toRems(-12px);
		margin-top: toRems(-12px);
		position: absolute;
		top: 50%;
		width: toRems(24px);
	}

	&--white {
		svg {
			fill: $c-white;
		}
	}
}
