.o-split-heading-action {
	display: block;
	margin-bottom: $spacing-small;
	width: 100%;

	@include breakpoint($bp-small) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}

	&__heading {
		@include breakpoint($bp-small) {
			flex: 1 1 auto;
			order: 1;
			padding-right: $spacing-small;
		}
	}

	&__action {
		margin: 0 auto $spacing-small;
		max-width: toRems(150px);

		@include breakpoint($bp-small) {
			flex: 1 1 toRems(150px);
			margin: 0;
			order: 2;
			width: toRems(150px);
		}
	}
}
