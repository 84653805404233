.o-block {
	padding-bottom: $spacing-base;

	@include breakpoint($bp-medium) {
		padding-bottom: toRems(40px);
	}

	&--medium {
		@include breakpoint($bp-medium) {
			padding-bottom: toRems(45px);
		}
	}

	&--small {
		padding-bottom: $spacing-medium;
	}

	&--small-vertical-pad {
		padding-top: $spacing-medium;
		padding-bottom: $spacing-medium;
	}
}
