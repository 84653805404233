.o-grid,
%o-grid {
    @include outer-container;
    position: relative;
    padding: 0 $spacing-small;

    @include breakpoint($bp-medium) {
        padding: 0 $spacing-base;
    }

    @include breakpoint($bp-large) {
        padding: 0 $spacing-medium;
    }

    &--outset {
        max-width: $max-site-width + 40px;
    }

    &--no-pad {
        padding: 0;
    }

    &--no-pad\@touchevents {
        .touchevents & {
            padding: 0;
        }
    }

    &--pad\@medium {
        padding: 0;

        @include breakpoint($bp-medium) {
            padding: 0 $spacing-base;
        }

        @include breakpoint($bp-large) {
            padding: 0 $spacing-medium;
        }
    }

    &--skinny {
        max-width: 780px;
    }

    &--skinny\@large-max {
        max-width: 660px;

        @include breakpoint($bp-large) {
            max-width: $max-site-width;
        }
    }
}
