form {
	@media print {
		display: none;
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	legend {

	}

	ol {
		@include clearfix;

		li {
			@include clearfix;
			float: left;
			margin-bottom: $spacing-base;
			width: 100%;

			&:last-child {
				margin-bottom: auto;
			}
		}
	}

	input {
		border-radius: 0;
		box-shadow: none;

		@include placeholder {
			color: rgba(palette(grey), 0.5);
		}
	}
}

button {
	background: none;
	border: none;
	padding: 0;
}
