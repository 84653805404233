.o-flex {
	display: flex;

	.no-flexbox & {
		display: table;
		width: 100%;
	}

	@include breakpoint($bp-xsmall) {
		display: block !important;
	}

	&__item {
		flex-grow: 1;
		margin: 0;

		.no-flexbox & {
			display: table-cell;
			float: none;
			width: auto;
		}

		@include breakpoint($bp-xsmall) {
			display: block !important;
			margin-bottom: toRems(3px);
		}
	}

	&--thin-spacing {
		.o-flex__item {
			padding: 0 toRems(2px);
		}
	}
}
