.o-split-content {
	@include breakpoint($bp-large) {
		display: table;
		width: 100%;
	}

	&__item {
		@include breakpoint($bp-large) {
			display: table-cell;
			vertical-align: bottom;

			&:first-child {
				padding-right: 20px;
			}

			&:last-child {
				text-align: right;
				width: toRems(200px);
			}
		}
	}
}
