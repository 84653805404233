.u-kaputa-sinhalese {
    &#{&} {
        font-family: "Kaputa Sinhalese", sans-serif;
    }
}

.u-tharmini-plain-tamil {
    &#{&} {
        font-family: "Tharmini Plain Tamil", sans-serif;
    }
}

.u-align-left {
    &#{&} {
        text-align: left;
    }
}

.u-align-center {
    &#{&} {
        text-align: center;
    }
}

.u-align-right {
    &#{&} {
        text-align: right;
    }
}

.u-uppercase {
    &#{&} {
        text-transform: uppercase;
    }
}

.u-lowercase {
    &#{&} {
        text-transform: lowercase;
    }
}

.u-no-case {
    &#{&} {
        text-transform: none;
    }
}

.u-red {
  color: palette(red);
}

.u-blue {
  color: palette(blue);
}

.u-pink {
  color: palette(pink);
}

.u-purple {
  color: palette(purple);
}