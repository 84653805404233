.c-filter-bar {
	@include clearfix;

	&__inner {
		@include clearfix;
		width: calc(100% - 210px);
		float: left;

		@include breakpoint($bp-medium) {
			width: calc(100% - 220px);
		}

		@include breakpoint($bp-xsmall) {
			float: none;
			width: 100%;
		}
	}

	&__item {
		margin: 0;
		width: 100%;

		@include breakpoint($bp-medium) {
			@include span-columns(6);
			@include omega(2n);
		}

		@include breakpoint($bp-xsmall) {
			margin-bottom: $spacing-small;
		}
	}

	&__action {
		float: right;

		@include breakpoint($bp-xsmall) {
			float: none;
		}
	}

	&--weighted-left {
		.c-filter-bar__inner {
			max-width: toRems(720px);

			@include breakpoint($bp-medium-max) {
				width: 100%;
			}
		}

		.c-filter-bar__item {
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			@include breakpoint($bp-small) {
				@include span-columns(4 of 8);
			}

			@include breakpoint($bp-medium-max) {
				margin-bottom: $spacing-small;
			}
		}
	}

	&--single-field {
		.c-filter-bar__item {
			@include breakpoint($bp-medium) {
				@include span-columns(8);
			}

			@include breakpoint($bp-large) {
				@include span-columns(6);
			}
		}
	}
}
