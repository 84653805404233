.c-form-legend,
%c-form-legend {
	display: block;
	font-size: toRems($base-font-size);
	font-weight: $font-weight-bold;
	margin-bottom: $spacing-xsmall;
	text-transform: uppercase;

	&--invisible {
		display: none;
		font-size: 0;
		height: 24px;

		@include breakpoint($bp-medium) {
			display: block;
		}
	}
}
