.c-list-item {
	@include clearfix;
	background-color: $c-white;
	color: $c-font;
	display: block;
	line-height: 1.4;
	padding: $spacing-small;
	position: relative;
	transition: all 0.5s $global-easing;

	@include breakpoint($bp-medium) {
		padding: $spacing-base;
	}

	&:link,
	&:visited {
		.c-list-item__title {
			color: palette(purple);
			border-color: palette(purple, light);
		}
	}

	&__figure {
		display: block;
		float: left;
		width: 33.33%;

		@include breakpoint($bp-large) {
			width: 20%;
		}

		@include breakpoint($bp-xsmall) {
			padding-left: 0;
			width: 100%;
		}
	}

	&__content {
		display: block;
		float: left;
		padding-left: $spacing-small;
		width: 66.66%;

		@include breakpoint($bp-medium) {
			padding-left: $spacing-base;
		}

		@include breakpoint($bp-large) {
			width: 80%;
		}

		@include breakpoint($bp-xsmall) {
			padding-left: 0;
			padding-top: $spacing-base;
			width: 100%;
		}
	}

	&__title {
		@extend %sans-serif-mid-large;
		border-bottom: 1px solid rgba($c-font, 0.25);
		display: inline;
		line-height: 1;
		margin: 0;

		a:not([class]) {
			&:hover {
				color: palette(purple);
			}
		}
	}

	&__body {
		margin: $spacing-base 0 $spacing-small;
		max-width: toRems(720px);

		&:last-child {
			margin-bottom: 0;
		}
	}

	// Modifiers
	&--small {
		background-color: transparent;
		padding: 0;

		.c-list-item__figure {
			width: 33.33%;
		}

		.c-list-item__content {
			padding-top: 0;
			padding-left: $spacing-small;
			width: 66.66%;
		}

		.c-list-item__title {
			font-size: toRems($base-font-size);
			font-weight: $font-weight-bold;
		}

		.c-list-item__body {
			margin-top: $spacing-xsmall;
		}
	}

	&--fixed-figure\@medium {
		@include breakpoint($bp-medium) {
			.c-list-item__figure {
				width: toRems(170px);
			}

			.c-list-item__content {
				width: calc(100% - 170px);
			}

			// Fixed Figure @ medium & Small
			&.c-list-item--small {
				.c-list-item__figure {
					width: toRems(120px);
				}

				.c-list-item__content {
					width: calc(100% - 120px);
				}
			}
		}
	}

	&--simple {
		background-color: $c-white;
		padding: $spacing-base;

		@include breakpoint($bp-medium) {
			padding: $spacing-medium;
		}

		.c-list-item__title {
			border: none;
			font-size: $font-size-4;
			font-weight: $font-weight-bold;
			margin-bottom: $spacing-xsmall;
		}

		.c-list-item__body {
			max-width: toRems(960px);
		}
	}

	&--body-align-bottom {
		@include breakpoint($bp-medium) {
			.c-list-item__body {
				margin-top: toRems(42px);
			}
		}
	}

	&--title-purple {
		.c-list-item__title {
			@include c-text-link;
			border: none;
			color: palette(purple);

			&:hover {
				color: palette(purple);
			}
		}
	}

	// Image spans the space it's been given
	&--full-height-image {

		@include breakpoint($bp-large) {
			display: flex;
		}
		.c-list-item__figure {
			a {
				@include breakpoint($bp-large) {
					display: block;
					height: 100%;
				}
			}
		}
	}
}

// List item also a link
a.c-list-item {
	&:hover {
		.c-list-item__title {
			color: palette(purple);

			&:before {
				background-color: palette(purple);
			}
		}
	}

	&--simple {
		&:hover {
			background-color: palette(purple);
			color: $c-white;

			.c-list-item__title {
				color: $c-white;
			}
		}
	}
}
