.c-icon-social {
	color: palette(color, light);
	font-size: toRems(24px);
	transition: color 0.2s ease-in-out;

	&[class*="facebook"]:hover {
		color: $c-facebook;
	}
	&[class*="google-plus"]:hover {
		color: $c-google-plus;
	}
	&[class*="instagram"]:hover {
		color: $c-instagram;
	}
	&[class*="linked-in"]:hover {
		color: $c-linked-in;
	}
	&[class*="pinterest"]:hover {
		color: $c-pinterest;
	}
	&[class*="twitter"]:hover {
		color: $c-twitter;
	}
	&[class*="youtube"]:hover {
		color: $c-youtube;
	}
}
