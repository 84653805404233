.c-btn-square {
	background-color: palette(purple, light);
	display: inline-block;
	height: toRems(45px);
	position: relative;
	width: toRems(45px);

	&:hover {
		background-color: palette(purple);
	}

	svg {
		fill: $c-white;
		height: toRems(24px);
		left: 50%;
		margin-left: toRems(-12px);
		margin-top: toRems(-12px);
		position: absolute;
		top: 50%;
		width: toRems(24px);
	}

	&[class*="facebook"] {
		background-color: $c-facebook;

		&:hover {
			background-color: shade($c-facebook, 33%);
		}
	}

	&[class*="google"] {
		background-color: $c-google-plus;

		&:hover {
			background-color: shade($c-google-plus, 33%);
		}
	}

	&[class*="instagram"] {
		background-color: $c-instagram;

		&:hover {
			background-color: shade($c-instagram, 33%);
		}
	}

	&[class*="linkedin"] {
		background-color: $c-linked-in;

		&:hover {
			background-color: shade($c-linked-in, 33%);
		}
	}

	&[class*="pinterest"] {
		background-color: $c-pinterest;

		&:hover {
			background-color: shade($c-pinterest, 33%);
		}

		// Add this removes svg in mark so have to use font icon
		&:before {
			@extend %i-pinterest;
			color: $c-white;
			font-size: 20px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&[class*="twitter"] {
		background-color: $c-twitter;

		&:hover {
			background-color: shade($c-twitter, 33%);
		}
	}

	&[class*="youtube"] {
		background-color: $c-youtube;

		&:hover {
			background-color: shade($c-youtube, 33%);
		}
	}

	&[class*="mail"] {
		background-color: palette(purple);

		&:hover {
			background-color: shade(palette(purple), 33%);
		}
	}

	&[class*="print"] {
		background-color: palette(grey);

		&:hover {
			background-color: shade(palette(grey), 33%);
		}
	}

	&--large {
		height: toRems(60px);
		vertical-align: middle;
		width: toRems(60px);
	}

	&--inverse {
		background-color: palette(purple);

		&:hover {
			background-color: palette(purple, light);
		}
	}
}
