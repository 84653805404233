/**
 * Generated Sprites
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more sprites to the spritesheets:
 *  1. Add pngs to "images/sprites/src/"
 *  2. Run `gulp sprites` in your terminal
 *
 */

%spritesheet {
	background-image: url(./images/sprites/spritesheet.png);
	display: block;
	content: "";

	@media (-webkit-min-device-pixel-ratio: 2),
		   (min-resolution: 192dpi) {
		background-image: url(./images/sprites/spritesheet@2x.png);
	}
}

.s-example,
%s-example {
	@extend %spritesheet;
	background-position: 0px 0px;
	width: 32px;
	height: 32px;
}

