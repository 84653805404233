.c-nav {
	@include material-shadow(1);
	background-color: $c-white;
	left: 0;
	margin-top: toRems(60px);
	position: fixed;
	top: 0;
	transform: translateY(-110%);
	transition: transform 0.5s $global-easing;
	width: 100%;
	z-index: $z-header - 10;

	@include breakpoint($bp-medium) {
		box-shadow: none;
		margin-top: 0;
		max-width: none;
		min-height: 0;
		position: static;
		transform: none;
	}

	@media print {
		display: none;
	}

	// Open state
	.js-nav-open & {
		position: relative;
		transform: none;
	}

	&__inner {
		display: table;
		table-layout: fixed;
		width: 100%;

		@include breakpoint($bp-medium) {
			display: block;
		}
	}

	&__wrap {
		padding: $spacing-medium $spacing-base;

		@include breakpoint($bp-medium) {
			padding: 0;
			width: auto;
		}
	}

	&__search-container {
		display: table-header-group;
	}

	&__primary-links {
		display: table-header-group;
		width: 100%;

		@include breakpoint($bp-medium) {
			display: block;
			margin-left: -$spacing-base;
			padding-bottom: toRems(10px);
			width: auto;
		}

		@include breakpoint($bp-large) {
			padding-bottom: 0;
		}
	}

	&__secondary-links {
		display: table-footer-group;
		width: 100%;

		@include breakpoint($bp-medium) {
			display: block;
		}

		@include breakpoint($bp-large) {
			margin-bottom: $spacing-small;
			width: auto;
		}
	}
}
