.o-card-container {
    @include clearfix;
    position: relative;

    &__item {
        margin-bottom: $spacing-base;

        @include breakpoint($bp-medium) {
            @include span-columns(6);
            @include omega(2n);
        }

        // First four items - 1/2 width (default seven items)
        &:nth-child(-n+4) {
            @include breakpoint($bp-medium) {
                @include span-columns(6);
				@include omega(2n);
            }
        }

        // Last three items - 1/3 width (default seven items)
        &:nth-child(n+5) {
            @include breakpoint($bp-medium) {
				@include span-columns(4 of 8);
				@include omega(2n);
				margin-bottom: flex-gutter(9);
			}

			@include breakpoint($bp-large) {
				@include span-columns(4);
				@include omega-reset(2n);
				margin-bottom: flex-gutter(13);
			}
        }

		&:nth-child(n+5) + &:nth-child(7),
		&:nth-child(n+5) + &:nth-child(10),
		&:nth-child(n+5) + &:nth-child(13) {
			@include breakpoint($bp-large) {
				margin-right: 0;
			}
		}

        // If there are only three items - 1/3 width
        &:nth-last-child(3):first-child,
        &:nth-last-child(3):first-child ~ & {
            @include breakpoint($bp-medium) {
                @include span-columns(4 of 8);
                @include omega(2n);
                margin-bottom: flex-gutter(9);
            }

            @include breakpoint($bp-large) {
                @include span-columns(4);
                @include omega-reset(2n);
                margin-bottom: flex-gutter(13);
            }
        }

        // If there are five items - last three items split 1/3
        &:nth-last-child(5):first-child ~ & {
            @include breakpoint($bp-medium) {
                @include span-columns(4 of 8);
                @include omega(2n);
                margin-bottom: flex-gutter(9);
            }

            @include breakpoint($bp-large) {
                @include span-columns(4);
                @include omega-reset(2n);
            }
        }

        // If there are five items - first two items split 1/2
        &:nth-last-child(5):first-child,
        &:nth-last-child(5):first-child + & {
            @include breakpoint($bp-medium) {
                @include span-columns(6);
                @include omega(2n);
            }
        }

        // If there are four / six / eight items - split into 1/2
        &:nth-last-child(4):first-child,
        &:nth-last-child(4):first-child ~ &,
        &:nth-last-child(6):first-child,
        &:nth-last-child(6):first-child ~ &,
        &:nth-last-child(8):first-child,
        &:nth-last-child(8):first-child ~ & {
            @include breakpoint($bp-medium) {
                @include span-columns(4 of 8);
                @include omega-reset(2n);
                @include omega(2n);
            }

			@include breakpoint($bp-large) {
				margin-bottom: flex-gutter(9);
			}
        }
    }
}
