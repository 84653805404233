.u-spacing-top-zero {
    &#{&} {
        margin-top: 0;
    }
}

.u-spacing-bottom-zero {
    &#{&} {
        margin-bottom: 0;
    }
}

.u-spacing-bottom-xxxsmall {
	&#{&} {
		margin-bottom: $spacing-xxxsmall;
	}
}

.u-spacing-top-xsmall {
    &#{&} {
        margin-top: $spacing-xsmall;
    }
}

.u-spacing-bottom-xsmall {
    &#{&} {
        margin-bottom: $spacing-xsmall;
    }
}

.u-spacing-top-small {
    &#{&} {
        margin-top: $spacing-small;
    }
}

.u-spacing-bottom-small {
    &#{&} {
        margin-bottom: $spacing-small;
    }
}

.u-spacing-top-base {
    &#{&} {
        margin-top: $spacing-base;
    }
}

.u-spacing-bottom-base {
    &#{&} {
        margin-bottom: $spacing-base;
    }
}

.u-spacing-top-medium {
    &#{&} {
        margin-top: $spacing-medium;
    }
}

.u-spacing-bottom-medium {
    &#{&} {
        margin-bottom: $spacing-medium;
    }
}

.u-spacing-top-large {
    &#{&} {
        margin-top: $spacing-large;
    }
}

.u-spacing-bottom-large {
    &#{&} {
        margin-bottom: $spacing-large;
    }
}

.u-overflow-hidden {
  overflow: hidden;
}

/**
* @bp-medium
*/

.u-spacing-top-zero\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-top: 0;
        }
    }
}

.u-spacing-bottom-zero\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-bottom: 0;
        }
    }
}

.u-spacing-top-small\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-top: $spacing-small;
        }
    }
}

.u-spacing-bottom-small\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-bottom: $spacing-small;
        }
    }
}

.u-spacing-top-base\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-top: $spacing-base;
        }
    }
}

.u-spacing-bottom-base\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-bottom: $spacing-base;
        }
    }
}

.u-spacing-top-medium\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-top: $spacing-medium;
        }
    }
}

.u-spacing-bottom-medium\@medium {
    &#{&} {
        @include breakpoint($bp-medium) {
            margin-bottom: $spacing-medium;
        }
    }
}
