%sans-serif-xlarge {
	font-family: $proxima-nova;
	font-size: $font-size-2;
	font-weight: $font-weight-black;
	line-height: 1;

	@include breakpoint($bp-medium) {
		font-size: $font-size-1;
	}

	@include breakpoint($bp-xsmall) {
		font-size: $font-size-3;
	}
}

%sans-serif-large {
	font-family: $proxima-nova;
	font-size: $font-size-3;
	font-weight: $font-weight-black;
	line-height: 1;

	@include breakpoint($bp-medium) {
		font-size: $font-size-2;
	}

	@include breakpoint($bp-xsmall) {
		font-size: toRems(24px);
	}
}

%sans-serif-mid-large {
	font-family: $proxima-nova;
	font-size: toRems(24px);
	font-weight: $font-weight-black;
	line-height: 1.3;

	@include breakpoint($bp-medium) {
		font-size: $font-size-3;
	}
}

%sans-serif-medium {
	font-family: $proxima-nova;
	font-size: toRems(24px);
	font-weight: $font-weight-bold;
	line-height: 1.2;

	@include breakpoint($bp-medium) {
		font-size: $font-size-3;
	}

	@include breakpoint($bp-xsmall) {
		font-size: toRems(21px);
	}
}

%sans-serif-base {
	font-family: $proxima-nova;
	font-size: $font-size-4;
	font-weight: $font-weight-bold;
	line-height: 1.22;
}

%sans-serif-xxsmall {
	font-family: $proxima-nova;
	font-size: $font-xxsmall;
	font-weight: $font-weight-normal;
	line-height: 1.22;
}
