.c-btn-top {
	border-top-left-radius: 5px;
	bottom: 0;
	color: palette(purple, light);
	display: block;
	font-weight: $font-weight-bold;
	line-height: 1;
	min-height: 44px;
	min-width: 44px;
	opacity: 0;
	padding: $spacing-small;
	position: fixed;
	right: 0;
	transform: translateX(50%);
	transition: visibility 0.5s $global-easing, opacity 0.5s $global-easing, transform 0.5s $global-easing;
	visibility: hidden;
	z-index: $z-btn-top;

	@include breakpoint($bp-medium) {
		box-shadow: none;
		padding: $spacing-base;
	}

	&:hover {
		@include breakpoint($bp-medium) {
			color: palette(purple);

			svg {
				fill: palette(purple);
			}
		}
	}

	svg {
		background-color: $c-white;
		display: block;
		fill: palette(purple, light);
		height: toRems(40px);
		margin: 0 auto $spacing-xsmall;
		transition: all 0.5s $global-easing;
		width: toRems(40px);
	}

	&.is-visible {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
}
