.c-notification {
	background-color: palette(green);
	color: $c-white;
	padding: $spacing-base;

	@include breakpoint($bp-medium) {
		display: flex;
		align-items: center;
		padding: $spacing-medium;
	}

	&__inner {
		margin-bottom: $spacing-base;

		@include breakpoint($bp-medium) {
			flex: 1 1 auto;
			margin-bottom: 0;
			margin-right: $spacing-medium;
		}

		&:last-child {
			margin: 0 !important;

			@include breakpoint($bp-medium) {
				flex: 0 1 auto;
			}
		}

		[class*="c-btn"] {
			white-space: nowrap;
		}
	}

	&__title {
		@extend %sans-serif-medium;
		font-weight: $font-weight-black;
		line-height: 1;
		margin-bottom: $spacing-xsmall;

		em {
			position: relative;
			font-style: normal;

			&:after {
				background-color: rgba($c-white, 0.5);
				bottom: toEms(1px);
				content: "";
				display: block;
				height: 2px;
				left: 0;
				position: absolute;
				width: 100%;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__subtitle {
		@extend %sans-serif-base;
		font-weight: $font-weight-semibold;
		line-height: 1.2;
		margin-bottom: 0;

		em {
			position: relative;
			font-style: normal;

			&:after {
				background-color: rgba($c-white, 0.5);
				bottom: 0;
				content: "";
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				width: 100%;
			}
		}
	}

	&--error {
		background-color: tint(palette(grey), 33%);
	}
}
