.c-picture-frame {
	img {
		width: 100%;
	}
	
	&__caption {
		font-weight: $font-weight-bold;
		line-height: 1.2;
		padding: $spacing-small 0;
	}
}
