.c-dropdown {
	display: inline-block;
	position: relative;
	transition: all 0.5s $global-easing;
	z-index: $z-header + 200;

	&__head {
		position: relative;
		text-align: left;
		z-index: $z-header + 202;

		@include breakpoint($bp-medium) {
			font-size: $font-small;
			font-weight: $font-weight-semibold;
			text-transform: uppercase;
		}
	}

	&__icon {
		display: inline-block;
		height: toRems(16px);
		margin-right: $spacing-xxsmall;
		position: relative;
		top: -2px;
		transition: all 0.5s $global-easing;
		vertical-align: middle;
		width: toRems(16px);
	}

	&__arrow {
		fill: $c-font !important;
		height: toRems(16px);
		margin-top: toRems(-8px);
		position: absolute;
		right: 0;
		top: 50%;
		transition: all 0.5s $global-easing;
		width: toRems(16px);

		@include breakpoint($bp-medium) {
			display: none;
		}
	}

	&__content {
		background-color: palette(grey, light);
		font-size: toRems($base-font-size);
		left: 0;
		line-height: $base-line-height;
		min-width: 100%;
		opacity: 0;
		overflow: scroll;
		position: absolute;
		top: 100%;
		max-height: 0;
		transform: translateY(-20px);
		transition: max-height 1s $global-easing, opacity 0.3s $global-easing, visibility 0.3s $global-easing, transform 0.5s $global-easing;
		visibility: hidden;
		z-index: $z-header + 201;

		@include breakpoint($bp-medium) {
			max-height: toRems(195px);
		}

		a {
			display: block;
			color: $c-font;

			&:hover {
				background-color: palette(purple);
				color: $c-white;
			}
		}
	}

	&--square {
		font-size: 0;
		line-height: 1;

		.c-dropdown__head {
			background-color: palette(purple, light);
			height: toRems(50px);
			width: toRems(50px);

			&:hover {
				background-color: palette(green);
			}
		}

		.c-dropdown__icon {
			fill: $c-white;
			height: toRems(18px);
			left: 50%;
			margin-left: -13px;
			margin-top: -9px;
			position: absolute;
			top: 50%;
			width: toRems(26px);
		}

		.c-dropdown__content {
			left: auto;
			max-height: toRems(200px);
			overflow-x: hidden;
			overflow-y: scroll;
			padding: $spacing-xsmall 0;
			right: 100%;
			top: 0;
			width: calc(100vw - 125px);

			@include breakpoint($bp-small) {
				width: toRems(300px);
			}

			a {
				padding: $spacing-xxsmall $spacing-small;

				@include breakpoint($bp-small) {
					padding: $spacing-xxsmall $spacing-base;
				}
			}
		}

		&.is-active {
			.c-dropdown__head {
				background-color: palette(green);
			}

			.c-dropdown__icon {
				transform: rotate(180deg);
			}
		}
	}

	&--block {
		display: block;

		@include breakpoint($bp-medium) {
			margin-left: -$spacing-small;
		}

		@include breakpoint($bp-medium-max) {
			box-shadow: none;
			display: block;
		}

		.c-dropdown__head {
			width: 100%;

			@include breakpoint($bp-medium) {
				padding: $spacing-xxsmall $spacing-small;
			}

			&:hover {
				@include breakpoint($bp-medium) {
					background-color: palette(grey, light);
				}
			}

			html[data-focus-source="key"] > body &:focus {
				outline: none !important;
				background-color: palette(a11y);
				color: $c-black;

				.c-dropdown__icon {
					fill: $c-black;
				}
			}
		}

		.c-dropdown__content {
			padding: $spacing-xsmall 0 0;

			@include breakpoint($bp-medium) {
				padding: $spacing-xxsmall 0 $spacing-xsmall;
				width: toRems(180px);
			}

			@include breakpoint($bp-medium-max) {
				background-color: transparent;
				box-shadow: none;
				opacity: 1 !important;
				position: relative;
				transform: none !important;
				visibility: visible !important;
			}

			a {
				line-height: 1.2;
				padding: toRems(3px) $spacing-small;

				@include breakpoint($bp-medium-max) {
					padding: 0;
				}
			}
		}

		&.is-active {
			@include breakpoint($bp-medium-max) {
				box-shadow: none !important;
			}

			.c-dropdown__icon {
				fill: palette(purple);
			}

			.c-dropdown__head {
				color: palette(purple);

				@include breakpoint($bp-medium) {
					background-color: palette(grey, light);
				}
			}

			.c-dropdown__content {
				@include breakpoint($bp-medium-max) {
					box-shadow: none !important;
					max-height: 500px;
					transition: max-height 1.5s $global-easing, opacity 0.3s $global-easing, visibility 0.3s $global-easing, transform 0.5s $global-easing;
				}

				a {
					@include breakpoint($bp-medium-max) {
						@include c-text-link;
					}

					&:hover {
						@include breakpoint($bp-medium-max) {
							background-color: transparent;
							color: palette(purple);
						}
					}
				}
			}
		}

		html[data-focus-source="key"] > body &:focus {
			background-color: palette(a11y) !important;
		}
	}

	&--no-bold {
		.c-dropdown__head {
			@include breakpoint($bp-medium) {
				font-weight: $font-weight-normal;
			}
		}
	}

	&.is-active {
		@include material-shadow(1);

		.c-dropdown__content {
			@include material-shadow(1);
			opacity: 1;
			visibility: visible;
			transform: none;
		}

		.c-dropdown__arrow {
			transform: rotate(180deg);
		}
	}
}

// Prevent request for chosen-sprite.png image from the Chosen plugin's default CSS.
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close,
.chosen-rtl .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
	background-image: none !important;
}
