.c-feature-article {
  @include clearfix;
  display: block;
  min-height: toRems(45px);
  padding-left: toRems(65px);
  position: relative;
  padding-top: 10px;

  @include breakpoint($bp-medium) {
    padding-left: toRems(120px);
    padding-top: 0;
  }

  @media print {
    height: auto !important;
  }

  &:hover {
    .c-feature-article__title {
      span {
        // Animate underline border
        &:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }

  &__link {
    color: $c-font;
  }

  &__icon {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: toRems(45px);

    @include breakpoint($bp-small) {
      top: 0;
      transform: none;
    }

    @include breakpoint($bp-medium) {
      width: toRems(90px);
    }

    img,
    svg {
      display: block;
      margin: 0 auto;
      max-height: 45px;
      width: 45px;

      @include breakpoint($bp-medium) {
        max-height: 90px;
        width: 90px;
      }
    }
  }

  &__title {
    @extend %sans-serif-mid-large;
    line-height: 1;
    transition: color 0.2s ease-in-out;
    flex: 1;

    @include breakpoint($bp-small) {
      margin-bottom: $spacing-base;
    }

    @include breakpoint($bp-medium) {
      margin-bottom: toRems(25px);
    }

    span {
      @include c-text-link;
    }

    a {
      border-bottom: 1px solid;
      color: inherit;

      @media print {
        border: none;
      }
    }
  }

  &__content {
    display: none;

    @include breakpoint($bp-small) {
      display: block;
    }

    @include breakpoint($bp-medium) {
      max-width: toRems(320px);
    }
  }

  &--large {
    margin-bottom: $spacing-base;
    padding-top: 0;

    @include breakpoint($bp-medium) {
      margin-bottom: 0;
      min-height: toRems(90px);
    }

    .c-feature-article__icon {
      top: 0;
      transform: none;
    }

    .c-feature-article__content {
      display: block;
      max-width: none;
    }

    .c-feature-article__title {
      @extend %sans-serif-large;
      margin-bottom: $spacing-small;
    }
  }

  &--cta {
    padding-left: 0;

    .c-feature-article__title {
      @extend %sans-serif-large;
      color: $c-white;
      margin-bottom: $spacing-small;

      &:hover {
        color: $c-white;
      }
    }

    .c-feature-article__content {
      display: block;
      max-width: none;
    }
  }

  &--right-aligned\@medium {
    @include breakpoint($bp-medium) {
      padding-left: 0;
      padding-right: toRems(120px);
      text-align: right;
    }

    .c-feature-article__icon {
      @include breakpoint($bp-medium) {
        left: auto;
        right: 0;
      }
    }

    .c-feature-article__content {
      @include breakpoint($bp-medium) {
        margin-left: auto;
      }
    }
  }

  &--tabs {
    padding: 0 0 $spacing-xsmall 0;

    @include breakpoint($bp-medium) {
      padding: 0;
    }

    .c-feature-article__link {
      align-items: center;
      border-bottom: 1px solid palette(grey, mid-light);
      border-right: 1px solid palette(grey, mid-light);
      display: flex;
      justify-content: flex-start;
      opacity: 0.7;
      text-decoration: none;
      transition: border 0.5s $global-easing, opacity 0.5s $global-easing;
      word-break: break-word;

      &:hover,
      &.is-active {
        opacity: 1;

        .c-feature-article__title {
          span {
            &:before {
              visibility: visible;
              transform: scaleX(1);
            }
          }
        }
      }

       &.is-active {
         border-right: 1px solid transparent;
         pointer-events: none;
       }
    }

    .c-feature-article__icon {
      display: inline-block;
      margin-right: 30px;
      padding: 0;
      position: static;
      transform: none;

      @include breakpoint($bp-medium) {
        margin-right: 0;
        padding: 8px 0;
      }

      svg,
      img {
        display: inline-block;
        height: toRems(50px);
        max-height: toRems(50px);
        vertical-align: middle;
        width: 40px;

        @include breakpoint($bp-medium) {
          display: block;
          height: toRems(80px);
          max-height: toRems(80px);
          width: 60px;
        }
      }
    }

    .c-feature-article__title {
      @extend %sans-serif-large;
      color: palette(grey, dark);
      display: inline-block;
      font-size: toRems(24px);
      margin-bottom: 0;
      text-align: left;
      vertical-align: middle;

      @include breakpoint($bp-medium) {
        display: block;
        font-size: $font-size-3;
      }

      @include breakpoint($bp-large) {
        font-size: $font-size-2;
      }

      span {
        @include c-text-link;
        
        &:before {
          background-color: palette(grey, mid-light);
        }
      }
    }
  }

  // Colors
  &--pink {
    .c-feature-article__title {
      color: palette(pink);

      span:before {
        background-color: palette(pink);
      }
    }
  }

  &--orange {
    .c-feature-article__title {
      color: palette(orange);

      span:before {
        background-color: palette(orange);
      }
    }
  }

  &--green {
    .c-feature-article__title {
      color: palette(green, mid-light);

      span:before {
        background-color: palette(green, mid-light);
      }
    }
  }

  &--blue {
    .c-feature-article__title {
      color: palette(blue);

      span:before {
        background-color: palette(blue);
      }
    }
  }

  &--red {
    .c-feature-article__title {
      color: palette(red);

      span:before {
        background-color: palette(red);
      }
    }
  }

  &--violet {
    .c-feature-article__title {
      color: palette(violet);

      span:before {
        background-color: palette(violet);
      }
    }
  }

  &--purple {
    .c-feature-article__title {
      color: palette(purple);

      span:before {
        background-color: palette(purple);
      }
    }
  }
}
