.c-search {
	background-color: palette(grey, light);
	padding: $spacing-base 0;
	position: relative;

	@include breakpoint($bp-medium-max) {
		display: none !important;
	}

	.js & {
		display: none;
	}

	&__btn {
		@extend %c-btn-close;
		display: none;
		position: absolute;
		right: $spacing-base;
		top: 0;

		@include breakpoint($bp-medium) {
			display: block;
			right: $spacing-medium;
		}
	}
}
