.c-loader,
%c-loader {
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);

	&:after {
		animation: spinner 1s infinite linear;
		border-color: rgba($c-white, 0.3) rgba($c-white, 0.3) rgba($c-white, 0.3) $c-white;
		border-radius: 40px;
		border-style: solid;
		border-width: 4px;
		content: "";
		display: block;
		height: toRems(50px);
		opacity: 0;
		transition-duration: transform 0.3s $global-easing, opacity 0.3s $global-easing;
		width: toRems(50px);
	}

	&--dark {
		&:after {
			border-color: rgba(palette(grey), 0.3) rgba(palette(grey), 0.3) rgba(palette(grey), 0.3) palette(grey, dark);
		}
	}

	&--small {
		&:after {
			width: 30px;
			height: 30px;
		}
	}

	&.is-visible {
		&:after {
			opacity: 1;
			transform: scale(1);
		}
	}
}
