.o-listing {
	@include clearfix;
	position: relative;

	&__item {
		margin-bottom: $spacing-small;

		@include breakpoint($bp-medium) {
			margin-bottom: flex-gutter(13);
		}

		&:last-child {
			@include breakpoint($bp-medium-max) {
				margin-bottom: 0 !important;
			}
		}
	}

	&--1\/2 {
		.o-listing__item {
			@include breakpoint($bp-medium) {
				@include span-columns(6);
				@include omega(2n);
				margin-bottom: 0;
			}
		}
	}

	&--1\/3 {
		.o-listing__item {
			@include breakpoint($bp-small) {
				@include span-columns(4 of 8);
				@include omega(2n);
				margin-bottom: flex-gutter(9);
			}

			@include breakpoint($bp-large) {
				@include span-columns(4);
				@include omega-reset(2n);
				@include omega(3n);
				margin-bottom: flex-gutter(13);
			}

			&:nth-child(3):last-child {
				@include breakpoint($bp-small) {
					width: 100%;
				}

				@include breakpoint($bp-large) {
					@include span-columns(4);
				}
			}

			&:nth-last-child(-n+3) {
				margin-bottom: 0;
			}
		}
	}

	&--1\/2\@large {
		.o-listing__item {
			margin-bottom: $spacing-small;

			@include breakpoint($bp-large) {
				@include span-columns(6);
				@include omega(2n);
			}
		}
	}

	&--1\/3\@large {
		.o-listing__item {
			@include breakpoint($bp-large) {
				@include span-columns(4);
				@include omega(3n);
			}
		}
	}

	&--divider {
		.o-listing__item {
			@include breakpoint($bp-medium) {
				@include span-columns(2 of 4);
				@include omega(2n);
				margin-bottom: $spacing-large;

				// Last two items
				&:nth-last-child(-n+2) {
					margin-bottom: 0;
				}
			}

			@include breakpoint($bp-large) {
				@include span-columns(1 of 2);
			}
		}

		&:before {
			background-color: rgba($c-black, 0.15);
			content: "";
			display: none;
			height: toRems(180px);
			left: 51%;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 1px;


			@include breakpoint($bp-medium) {
				display: block;
			}
		}
	}
	&--center {
		.o-listing__item {
			@include breakpoint($bp-medium) {
				flex: 1 0 auto;
			}
		}

		@include breakpoint($bp-medium) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

		}
	}
}
