.c-header {
  @include clearfix;
  @include material-shadow(1);
  background-color: $c-white;
  height: toRems(60px);
  left: 0;
  min-width: toRems(320px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-header;

  @media print {
    position: static;
  }

  &__drawer {
    display: none;
    background: $c-white;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;

    @include breakpoint($bp-medium) {
      display: block;
    }

    &:after {
      bottom: 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      content: '';
      height: 10%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  @include breakpoint($bp-medium) {
    box-shadow: none;
    height: auto;
  }

  &__inner {
    @include clearfix;
    position: relative;

    @include breakpoint($bp-medium) {
      padding-bottom: toRems(20px);
    }

    @include breakpoint($bp-large) {
      height: toRems(125px);
    }
  }

  &__logo {
    display: block;
    float: left;
    padding: $spacing-xsmall;

    @include breakpoint($bp-medium) {
      padding: toRems(25px) 0 0;
    }

    svg {
      display: block;
      height: toRems(45px);
      width: toRems(95px);

      @include breakpoint($bp-medium) {
        height: toRems(60px);
        width: toRems(130px);
      }

      @include breakpoint($bp-large) {
        height: toRems(80px);
        width: toRems(180px);
      }
    }
  }

  &__nav-container {
    float: left;

    @include breakpoint($bp-medium) {
      padding-top: $spacing-base;
      padding-left: toRems(25px);
    }

    @include breakpoint($bp-xlarge) {
      padding-left: toRems(75px);
    }
  }

  &__utilities {
    @include breakpoint($bp-medium) {
      @include clearfix;
      float: left;
      width: 100%;
      border-top: 1px solid rgba(palette(purple), 0.25);
      padding-top: 10px;
    }

    @include breakpoint($bp-large) {
      border: none;
      float: none;
      padding: 0;
      margin: 0;
    }

    @media print {
      display: none;
    }
  }

  &__links {
    float: right;

    @include breakpoint($bp-medium) {
      align-items: center;
      display: flex;
      float: right;
      height: toRems(60px);
      justify-content: space-between;
      padding-top: 3px;
    }

    @include breakpoint($bp-large) {
      height: auto;
      padding: 0;
      position: absolute;
      right: toRems(-10px);
      top: 0;
    }
  }

  &__donation {
    float: right;

    @include breakpoint($bp-medium) {
      float: left;
    }

    @include breakpoint($bp-large) {
      position: absolute;
      right: 0;
      bottom: toRems(20px);
    }
  }

  &__item {
    float: left;
    font-size: 0;
    line-height: 1;

    @include breakpoint($bp-medium) {
      float: none;
      margin: 0 toRems(10px);
    }
  }

  &--shadow {
    @include material-shadow(1);
  }

  .js-search-open & {
    @include material-shadow(1);
  }
}


:lang(en) .c-header__nav-container {
  @include breakpoint($bp-xlarge) {
    padding-left: toRems(100px);
  }
}