.c-icon {
  margin: 0 auto;
  width: toRems(45px);

  @include breakpoint($bp-medium) {
    width: toRems(90px);
  }

  img {
    display: block;
    max-height: 45px;
    width: 45px;
    margin: 0 auto;

    @include breakpoint($bp-medium) {
      max-height: 90px;
      width: 90px;
    }
  }
}
