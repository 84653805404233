.fui-field-container,
%fui-field-container {
  background-color: $c-white;
  opacity: 1;
  transition: opacity 0.5s $global-easing;
  position: relative;
  z-index: 1;

  label {
    border-bottom: 1px solid palette(grey, mid-light);
    color: $c-font;
    font-weight: $font-weight-bold;
    left: $spacing-base;
    line-height: 1.1;
    position: absolute;
    top: toRems(22px);
    transition: all 0.3s $global-easing;
    z-index: -1;
    font-size: 1rem;

    @include breakpoint($bp-xsmall) {
      white-space: nowrap;
    }
  }

  .fui-required {
    color: #434345!important;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border-radius: 0!important;
    border: 1px solid $c-white;
    color: $c-font;
    display: block;
    font-size: toRems($base-font-size);
    min-height: toRems(60px);
    padding: toRems(31px) $spacing-xsmall toRems(7px);
    text-overflow: ellipsis;
    width: 100%;
    box-shadow: none!important;

    @include placeholder {
      color: $c-white;
      font-size: 0;
    }

    &:focus {
      border-color: palette(purple, light) !important;
    }

    &:disabled {
      opacity: 1;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 toRems(1000px) $c-white inset;
    }
  }

  textarea {
    background-color: transparent;
    min-height: toRems(200px);
    resize: vertical;
    padding: toRems(31px) $spacing-xsmall toRems(7px);
    border-radius: 0;
    border: 0;
  }

  .parsley-errors-list {
    @include clearfix;

    li {
      @extend %c-form-error;
    }
  }

  &.parsley-error {
    .c-form-text__input {
      border-color: palette(errors);
    }

    .parsley-errors-list li {
      display: block;
    }
  }

  &.is-active {
    &:before {
      opacity: 1;
      transform: none;
      transition: all 0.5s $global-easing 0.2s;
    }

    label {
      border: none;
      color: palette(purple);
      left: $spacing-xsmall;
      top: $spacing-xsmall;
      z-index: 1;
    }
  }

  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--purple {
    background-color: palette(purple);

    label {
      color: $c-white !important;
      border-bottom: 1px solid rgba($c-white, 0.25);
    }

    input {
      border-color: palette(purple);
      color: $c-white;

      @include placeholder {
        color: palette(purple);
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 toRems(1000px) palette(purple) inset;
      }
    }
  }

  &--light-purple {
    background-color: palette(purple, x-light);

    .c-form-text__input {
      border-color: palette(purple, x-light);

      @include placeholder {
        color: palette(purple, x-light);
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 toRems(1000px) palette(purple, x-light) inset;
      }
    }
  }

  &--dollar {
    &:before {
      color: rgba($c-font, 0.7);
      content: "$";
      font-size: toRems($base-font-size);
      left: $spacing-xsmall;
      opacity: 0;
      position: absolute;
      top: $spacing-medium;
      transform: translateY(-5px);
      transition: all 0.3s $global-easing;
    }

    .c-form-text__input {
      padding-left: toRems(25px);
    }
  }
}

[data-field-type="agree"] {
  .fui-checkbox, .fui-field-container {
    padding:0;
    margin:0;
    border: none;
    background: none;
  }
}

body .fui-i {
  font-family: inherit;
}

div.fui-checkbox label {
  line-height: 1.8rem;
  ::before {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.fui-type-dropdown, .fui-type-entries {
  .fui-field-container {
    position: relative;

    &:before {
      background-color: #8d88c2;
      content: "";
      height: 40px;
      height: 2.5rem;
      right: 10px;
      right: 0.625rem;
      transition: all .5s cubic-bezier(.165,.84,.44,1);
      width: 40px;
      width: 2.5rem;
      display: block;
      pointer-events: none;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;
    }

    &:after {
      color: #fff;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 1;
      right: 23px;
      display: block;
      pointer-events: none;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "\E004";
      z-index: 3;
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: contain;
      color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 11' fill='white'%3E%3Cpath d='M17 2.5L8.5 11 0 2.5 2.5 0l6 6 6-6L17 2.5z'/%3E%3C/svg%3E");
    }
  }
}

.fui-type-entries {
  margin-top: -10px;

  .fui-field-container {
    border: 1px solid #D1D5DB;

    &:before {
      right: 0!important;
    }

    &:after {
      right: 14px!important;
    }
  }
}

.fui-input-container, .fui-field-container {

  .fui-repeater-row {
    background: white;
    border-radius: 0;
    border: 0;
  }

  .fui-legend {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.22;
  }

  .fui-checkbox {
    margin-right: 1.875rem!important;
  }

  .fui-checkbox label, .fui-radio label {
    padding-left: 35px;

  }

  .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
    background-color: #8d88c2!important;
    border-color: #8d88c2!important;
    box-shadow: none!important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='white'%3E%3Cpath d='M8.294 16.998c-0.435 0-0.847-0.203-1.111-0.553l-3.573-4.721c-0.465-0.613-0.344-1.486 0.27-1.951 0.615-0.467 1.488-0.344 1.953 0.270l2.351 3.104 5.911-9.492c0.407-0.652 1.267-0.852 1.921-0.445s0.854 1.266 0.446 1.920l-6.984 11.210c-0.242 0.391-0.661 0.635-1.120 0.656-0.022 0.002-0.042 0.002-0.064 0.002z'%3E%3C/path%3E%3C/svg%3E");    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 70%;
  }

  .fui-checkbox input:checked + label::after, .fui-radio input:checked + label::after {
    background-color: #8d88c2;
    border-color: #8d88c2;
  }

  .fui-checkbox label::before, .fui-radio label::before {
    border-radius: 0!important;
    height: 20px;
    width: 20px;
    background-image: none!important;
  }

  .fui-select {
    font-size: 1rem!important;
    border: 0!important;
    background: none!important;
    padding-right: 60px!important;
    border-radius: 0;
    padding-left: 20px;
    box-shadow: none!important;
  }

  .fui-repeater-add-btn {
    background: white;
    border-radius: 0;
    font-weight: 700;
  }

  .fui-heading.fui-heading-h2 {
    font-size: 1rem;
  }
}

#mc_embed_signup {
  background: transparent!important;
  width: auto!important;
  font-size: 1rem!important;
}

#mc_embed_signup .mc-field-group {
  padding-bottom: 0!important;
  margin-bottom: 1.25rem;
  min-height: auto!important;
  width: 100%!important;
}

.fui-btn-wrapper button.fui-submit {
  font-size: 1.125rem!important;
  border-color: #5e56a5!important;
  min-width: 13.125rem!important;
  color: white;

  &#mc-embedded-subscribe {
    margin: 0!important;
  }
}

#mc_embed_signup {
  font: inherit!important;
  
  #mc-embedded-subscribe-form div.mce_inline_error {
    background: transparent!important;
    margin-bottom: 0!important;
    padding: 0!important;
    margin-top: 5px!important;
    font-size: 12px;
  }
}

#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
    border: 1px solid #e85c41!important;
}

#mc_embed_signup .mc-field-group .fui-field-container input {
  border: 1px solid white;
  text-indent: 0!important;
  padding: 1.9375rem 0.625rem 0.4375rem;
}

#mc_embed_signup .mc-field-group .fui-field-container.is-active label {
  top: 0.25rem;
}

#mc_embed_signup .mc-field-group label {
  top: 15px;
  border: 0;
}

.fui-form {
  padding-bottom: 1.25rem;
}