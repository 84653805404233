.c-table {
	border-collapse: collapse;
	border: 1px solid palette(grey, mid-light);
	margin-bottom: 1em;
	width: 100%;

	@include breakpoint($bp-medium) {
		border: 1px solid palette(grey, light);
	}

	thead {
		background-color: palette(purple);
		color: $c-white;
		display: none;

		@include breakpoint($bp-medium) {
			display: table-header-group;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid palette(grey, mid-light);

			@include breakpoint($bp-medium) {
				border-bottom: 1px solid palette(grey, light);
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}

	tr {
		@include clearfix;
		display: flex;
		flex-wrap: wrap;
		padding: $spacing-xsmall 0;

		@include breakpoint($bp-medium) {
			display: table-row;
			padding: 0;
		}

		&:before,
		&:after {
			@include breakpoint($bp-medium) {
				display: none;
			}
		}
	}

	td {
		flex: 1 1 100%;
		display: block;
		padding: $spacing-xsmall $spacing-base;
		text-align: left;
		vertical-align: top;

		@include breakpoint($bp-small) {
			flex: 1 1 50%;
		}

		@include breakpoint($bp-medium) {
			border-right: 1px solid palette(grey, light);
			display: table-cell;
			padding: $spacing-medium $spacing-base;
			width: auto;
		}

		&:last-child {
			@include breakpoint($bp-medium) {
				border: none;
			}
		}

		&:before {
			color: palette(purple);
			content: attr(data-label)':';
			display: block;
			font-weight: $font-weight-bold;
			line-height: 1.4;
			margin-bottom: $spacing-xsmall;
			text-decoration: underline;

			@include breakpoint($bp-medium) {
				display: none;
			}
		}
	}

	th {
		display: none;
		font-size: $font-size-4;
		font-weight: $font-weight-bold;
		padding: $spacing-base;
		line-height: 1.2;
		text-align: left;

		@include breakpoint($bp-medium) {
			border-right: 1px solid palette(grey, light);
			display: table-cell;
		}

		&:last-child {
			@include breakpoint($bp-medium) {
				border: none;
			}
		}
	}

	tfoot {

	}

	&--small {
		font-size: $font-small;
	}

	&--simple {
		margin-bottom: 0;

		tbody {
			tr {
				background: none !important;
				border: none;
			}

			td {
				padding: 0;

				&:first-child {
					padding-left: 0;
					padding-right: $spacing-small;
				}
			}
		}
	}
}
