.c-slideshow {
	@extend %c-img-frame;
	background-color: $c-white;
	margin: 0 !important;
	max-height: 300px;
	padding-bottom: toRems(70px);
	position: relative;

	@include breakpoint($bp-medium) {
		margin: 0 !important;
		max-height: 500px;
		padding-bottom: 0;
	}

	@media print {
		height: auto !important;
	}

	.c-hero {
		margin-bottom: 0;

		&__content {
			@include breakpoint($bp-medium) {
				min-height: toRems(180px);
				padding-bottom: toRems(60px);
			}
		}
	}

	&.slick-initialized {
		background-color: transparent;
		max-height: 1000px;
		overflow: auto;

		&:before,
		&:after {
			display: none;
		}

		.c-slideshow__item {
			opacity: 1;
		}
	}

	&__item {
		opacity: 0;
		transition: opacity 0.5s $global-easing;
	}

	&__next,
	&__prev {
		@include material-shadow(1);
		background-color: palette(purple);
		border-radius: 0;
		height: toRems(50px);
		position: absolute;
		transform: translateY(20px);
		width: toRems(50px);
		bottom: $spacing-base;
		z-index: 10;

		svg {
			fill: $c-white;
			height: toRems(18px);
			left: 50%;
			margin-left: -13px;
			margin-top: -9px;
			position: absolute;
			top: 50%;
			width: toRems(26px);
		}
	}

	&__prev {
		left: 0;
	}

	&__next {
		left: toRems(53px);
	}

	&__dots {
		background-color: palette(purple);
		padding: 0 $spacing-small $spacing-small;

		@include breakpoint($bp-small) {
			padding: $spacing-small toRems(25px);
		}

		@include breakpoint($bp-medium) {
			background-color: transparent;
			bottom: toRems(35px);
			left: $spacing-large - toRems(3px);
			padding: 0;
			position: absolute;
		}

		li {
			display: inline-block;

			&.slick-active button:before {
				background-color: $c-white;
			}
		}

		button {
			display: block;
			font-size: 0;
			padding: 3px;

			&:before {
				border-radius: 100%;
				border: 2px solid $c-white;
				content: "";
				display: block;
				display: block;
				font-size: 0;
				height: toRems(16px);
				transition: all 0.3s $global-easing;
				width: toRems(16px);
			}
		}
	}

	&--feature {
		background-color: palette(grey, light);
		margin: 0 0 $spacing-base !important;
		padding-bottom: 0;

		@include breakpoint($bp-medium) {
			margin: 0 0 $spacing-large !important;
		}

		&:hover {
			.c-slideshow__next,
			.c-slideshow__prev {
				opacity: 1;
				transform: none;
			}
		}

		.c-slideshow__item {
			opacity: 1;
			position: relative;
			transform: none;
		}

		.c-slideshow__next,
		.c-slideshow__prev {
			background-color: $c-white;
			left: auto;
			position: absolute;
			transform: none;
			z-index: 10;

			@include breakpoint($bp-medium) {
				background-color: palette(purple);
			}

			svg {
				fill: palette(purple);

				@include breakpoint($bp-medium) {
					fill: $c-white;
				}
			}

			html[data-focus-source="key"] > body &:focus {
				opacity: 1;
				transform: none;
			}

			@include breakpoint($bp-medium) {
				bottom: $spacing-base;
				opacity: 0;
				top: auto;
				transform: translateY(20px);
			}
		}

		.c-slideshow__prev {
			right: toRems(53px) + $spacing-base;

			@include breakpoint($bp-medium) {
				right: toRems(53px) + $spacing-medium;
			}
		}

		.c-slideshow__next {
			right: $spacing-base;

			@include breakpoint($bp-medium) {
				right: $spacing-medium;
			}
		}
	}
}
