.c-hide-show {

    &.is-expanded {
        .c-hide-show__trigger {
            display: none;
        }
    }

    &__trigger {
        border-bottom: 1px solid rgba(palette(purple, light), 0.5);
        color: $c-link;
        font-weight: $font-weight-bold;

        &:hover {
            color: $c-link-hover;
            border-bottom-color: palette(purple);
        }
    }

    &__content {
        .js & {
            display: none;
        }
    }
}
