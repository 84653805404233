.o-content-wrap  {
	@include clearfix;
	background-color: $c-white;
	display: block;
	padding: $spacing-base;
	position: relative;
	width: 100%;

	@include breakpoint($bp-xsmall) {
		padding: $spacing-small;
	}

	@media print {
		padding: 0;
	}

	&__inner {
		max-width: 960px;
		margin: 0 auto;
	}

	&__share {
		@include breakpoint($bp-xxlarge) {
			left: 100%;
			padding-left: $spacing-xsmall;
			position: absolute;
			top: 0;
		}
	}

	&--negative-top\@medium {
		@include breakpoint($bp-medium) {
			margin-top: toRems(-130px);
		}
	}

	&--no-pad\@medium-max {
		@include breakpoint($bp-medium-max) {
			padding: 0;
		}
	}

	&--not-block{
		padding-top:0 !important;
		padding-bottom:0 !important;
	}
	&--gallery{
		margin: 1.875rem 0;
	}

	&--large {
		padding: $spacing-medium;

		@include breakpoint($bp-large) {
			padding: toRems(40px) toRems(90px);
		}

		@include breakpoint($bp-xsmall) {
			padding: $spacing-small;
		}

		p {
			&:first-of-type:first-child {
				strong {
					@extend %sans-serif-mid-large;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}
