*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

// Text highlight
::selection {
	color: $text-highlight-color;
	background: $text-highlight-bg-color;
}

::-moz-selection {
	color: $text-highlight-color;
	background: $text-highlight-bg-color;
}

html,
body {
	-moz-osx-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	-webkit-font-smoothing: antialiased !important;
	color: $c-font;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	min-width: 320px;
	position: relative;
	text-rendering: optimizeLegibility !important;
	width: 100%;
	z-index: 0;
}

body {
	font-size: toRems($base-font-size);
	transition: opacity 0.3s $global-easing;
	overflow-x: hidden;

	&:after {
		background-color: rgba($c-white, 0.75);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		transition: all 0.5s $global-easing;
		visibility: hidden;
		width: 100%;
		z-index: 100;

		@include breakpoint($bp-medium) {
			background-color: rgba($c-white, 0.5);
		}

		.js-nav-open &,
		.js-nav-drawer-active & {
			opacity: 1;
			visibility: visible;
		}
	}
}

// Links
a[href*="mailto:"] {
	word-wrap: break-word;
}

button {
	background-color: transparent;
	border: none;
	border-radius: 0;
	outline: none;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;

	a:not([class]) {
		color: inherit;
	}
}

// Reset Lists
ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Images
figure {
	margin: 0;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

a {
	color: $c-link;
	text-decoration: none;
}

// Paragraph
p {
	margin-top: 0;
	margin-bottom: $spacing-medium;

	b,
	strong {
		font-weight: $font-weight-semibold;
	}

	i,
	em {
		font-style: italic;
	}
}

// Default Transitions
a,
input[type="text"],
textarea,
button,
[class^="c-btn"] {
	-webkit-tap-highlight-color: $tap-highlight-color;
	transition-duration: 0.5s;
	transition-property: background-color, color, opacity, border, border-color, background-position, outline, box-shadow, border-bottom, text-decoration, left, top, transform;
	transition-timing-function: $global-easing;
	transform-origin: center center;
}
