.o-section {
	@include row;
	display: block;
	padding: $spacing-medium 0;

	@include breakpoint($bp-medium) {
		padding: toRems(40px) 0;
	}

	// UA-217 - Reduce padding when two grey sections are set together
	&.u-bg-grey-light + &.u-bg-grey-light {
		padding-top: 0;
	}

	.o-block {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&--medium {
		@include breakpoint($bp-medium) {
			padding: $spacing-medium 0;
		}
	}

	&--no-top {
		padding-top: 0;
	}

	&--no-bottom {
		padding-bottom: 0;
	}

	&--small-top {
		padding-top: $spacing-base;
	}

	&--not-block {
		padding-top: 0;
		padding-bottom: 0;

		article {
			padding: 0 80px;
		}
	}

	&--small-bottom {
		padding-bottom: $spacing-base;
	}

	&--medium-top\@medium {
		@include breakpoint($bp-medium) {
			padding-top: toRems(40px);
		}
	}

	&--medium-bottom\@medium {
		@include breakpoint($bp-medium) {
			padding-bottom: toRems(40px);
		}
	}

	&--no-bottom\@medium-max {
		@include breakpoint($bp-medium-max) {
			padding-bottom: 0;
		}
	}
}
