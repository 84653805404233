@keyframes spinner {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes flashing {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes up-down {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(4px);
    }

    60% {
        transform: translateY(0);
    }
}
