.c-accordion {
    background-color: $c-white;
    text-align: left;

    &__head {
        color: palette(grey, dark);
        font-size: 0;
        min-height: toRems(64px);
        padding: $spacing-small toRems(80px) $spacing-small $spacing-base;
        text-align: left;
        transition: all 0.3s $global-easing;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        width: 100%;

        @include breakpoint($bp-medium) {
            padding: $spacing-small toRems(120px) $spacing-small $spacing-base;
        }

        &:hover {
            .c-accordion__title {
                color: palette(purple);
            }

            .c-accordion__icon {
                background-color: palette(purple);
            }
        }
    }

    &__title {
        @extend %sans-serif-base;
        border-bottom: 1px solid rgba($c-font, 0.25);
        display: inline;
        line-height: 1.4;
        margin-bottom: 0;
    }

    &__icon {
        background-color: palette(purple, light);
        display: block;
        height: toRems(44px);
        position: absolute;
        right: $spacing-xsmall;
        top: $spacing-xsmall;
        transition: background-color 0.5s $global-easing;
        width: toRems(44px);

        svg {
            fill: $c-white;
            height: toRems(12px);
            left: 50%;
            margin-left: -9px;
            margin-top: toRems(-6px);
            position: absolute;
            top: 50%;
            transition: transform 0.5s $global-easing;
            width: toRems(18px);
        }
    }

    &__drawer {
        padding: $spacing-base;

        .js & {
            display: none;
        }
    }

    &__body {
        max-width: toRems(960px);
    }

    &.is-visible {
        .c-accordion__head {
            svg {
                transform: rotate(180deg);
            }
        }

        .c-accordion__title {
            @extend %sans-serif-mid-large;
            color: palette(purple);
            border-color: palette(purple, light);
        }
    }

    &--tabs {
      border-bottom: 1px solid palette(grey, mid-light);
      margin: $spacing-small 0;

      @include breakpoint($bp-medium) {
        border-bottom: 0;
        display: block;
        margin: 0;
      }

      .c-accordion__drawer {
        .js & {
          display: none;

          @include breakpoint($bp-medium) {
            display: block;
          }
        }
      }


      &.is-visible {
        .c-accordion__head {
          .c-feature-article__icon {
            svg {
              transform: none;
            }
          }

          .c-feature-article__title {
            span {
              &:before {
                visibility: visible;
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
}
