.c-contact-panel {
	padding: $spacing-base 0;

	@include breakpoint($bp-medium) {
		padding: $spacing-medium 0 $spacing-large;
	}

	&__inner {
		margin-bottom: $spacing-medium;

		@include breakpoint($bp-medium) {
			margin-bottom: 0;
		}
	}

	&__title {
		@extend %sans-serif-large;
		margin-bottom: $spacing-base;

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-medium;
		}
	}
}
