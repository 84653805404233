@mixin tab {
  align-items: flex-start;
  background-color: $c-white;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@mixin tab-nav {
  min-width: toRems(300px);
  width: 40%;

  @include breakpoint($bp-large) {
    min-width: toRems(360px);
  }
}

@mixin tab-nav-item {
  flex: 1 1 100%;
  text-align: center;
  display: block;

  &:last-child {
    .c-feature-article__link {
      border-bottom: 0;
    } 
  }
}

@mixin tab-content {
  background: $c-white;
  position: relative;
  width: 100%;

  @include breakpoint($bp-medium) {
    background: $c-white;
    padding: $spacing-base;
  }

  .c-accordion__head {
    @include breakpoint($bp-medium) {
      display: none;
    }
  }
}

@mixin tab-content-inner {
  padding: $spacing-base 0;

  @include breakpoint($bp-medium) {
    bottom: 0;
    opacity: 0;
    padding: $spacing-base;
    position: absolute;
    top: $spacing-medium;
    transition: opacity 0.3s $global-easing;
    visibility: hidden;
    width: 100%;
  }


  @include breakpoint($bp-large) {
    padding: $spacing-medium toRems(70px);
  }

  .js & {
    @include breakpoint($bp-medium) {
      opacity: 0;
    }
  }

  &.is-visible {
    @include breakpoint($bp-medium) {
      opacity: 1;
      position: static;
      visibility: visible;
    }
  }
}



.c-tab {
  @include tab;

  &__nav {
    @include tab-nav;
  }

  &__nav-item {
    @include tab-nav-item;
  }

  &__content {
    @include tab-content;
  }

  &__content-inner {
    @include tab-content-inner;
  }
}