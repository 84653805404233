html[data-focus-source="key"] > body {

	// Global keyboard focus styling
	& :focus {
		outline: 5px solid palette(a11y) !important;
		transition: none;
	}

	// Plain links
	& a:not([class*="c-btn"]):focus {
		background-color: palette(a11y);
		color: $c-black;

		svg {
			fill: $c-black;
		}
	}
}

html[data-focus-source="pointer"] > body :focus {
	outline: none;
}
