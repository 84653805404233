// Site
$max-site-width: 1260px;

// Font families
$proxima-nova: "proxima-nova", Arial, sans-serif;

// Font sizes
$font-size-1: toRems(42px);
$font-size-2: toRems(36px);
$font-size-3: toRems(28px);
$font-size-4: toRems(18px);

// Small font sizes
$font-small: toRems(14px);
$font-xsmall: toRems(13px);
$font-xxsmall: toRems(12px);

// Font weights
$font-weight-thin: 100;
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

// Base Variables
$base-font-family: $proxima-nova;
$base-font-size: 16px;
$base-font-weight: 400;
$base-line-height: 1.5;

// Neat grid settings
$grid-columns: 12;
$max-width: $max-site-width;
$visual-grid: false !default;
$border-box-sizing: true !default;
$disable-warnings: false !default;
$column: 2em;
$gutter: 0.5em;

// Have to import Neat after grid settings
@import "neat", // Neat - http://neat.bourbon.io/
        "node_modules/breakpoint-sass/stylesheets/breakpoint.scss"; // Breakpoints - http://breakpoint-sass.com/

// Breakpoints
$bp-xxlarge: min-width 1400px;
$bp-xlarge: min-width 1200px;
$bp-large: min-width 992px;
$bp-medium: min-width 768px;
$bp-small: min-width 540px;
$bp-xsmall: max-width 540px;

$bp-medium-max: max-width 767px;

// Z-indexes
$z-btn-top: 1000;
$z-header: 2000;
$z-modal: 3000;

// Global variables
$global-radius: 0;
$global-easing: cubic-bezier(0.165, 0.84, 0.44, 1);

// Global spacing
$spacing-xxxsmall: toRems(3px);
$spacing-xxsmall: toRems(5px);
$spacing-xsmall: toRems(10px);
$spacing-small: toRems(15px);
$spacing-base: toRems(20px);
$spacing-medium: toRems(30px);
$spacing-large: toRems(50px);
$spacing-xlarge: toRems(100px);
