.c-search-bar {
	display: block;
	position: relative;

	@include breakpoint($bp-medium) {
		width: calc(100% - 80px);
	}

	&__label {
		@include visually-hidden;
	}

	&__input {
		background: $c-white;
		border: 1px solid $c-white;
		color: palette(purple);
		font-size: $font-size-4;
		font-weight: $font-weight-black;
		min-height: toRems(60px);
		padding: toRems(10px) toRems(60px) toRems(10px) $spacing-base;
		width: 100%;

		@include breakpoint($bp-medium) {
			font-size: $font-size-3;
			padding: toRems(10px) toRems(120px) toRems(10px) $spacing-base;
		}
	}

	&__btn {
		color: palette(purple);
		font-size: toRems(15px);
		font-weight: $font-weight-bold;
		height: 100%;
		padding: $spacing-small $spacing-base;
		position: absolute;
		right: 0;
		text-transform: uppercase;
		top: 0;

		@include breakpoint($bp-medium) {
			padding: $spacing-small $spacing-medium;
		}

		@include breakpoint($bp-medium-max) {
			font-size: 0;
		}

		&:hover {
			background-color: palette(purple);
			color: $c-white;

			svg {
				fill: $c-white;
			}
		}

		svg {
			fill: palette(purple);
			width: toRems(24px);
			height: toRems(24px);
			transition: all 0.5s $global-easing;

			@include breakpoint($bp-medium) {
				display: none;
			}
		}
	}

	&--results {
		width: 100%;

		.c-search-bar__input {
			padding-right: toRems(70px);
		}

		.c-search-bar__btn {
			background-color: palette(purple, light);
			border: 10px solid $c-white;
			font-size: 0;
			padding: 0;
			width: 60px;

			&:hover {
				background-color: palette(purple);
			}

			svg {
				display: block;
				fill: $c-white;
				left: 50%;
				margin-left: toRems(-12px);
				margin-top: toRems(-12px);
				position: absolute;
				top: 50%;
			}
		}
	}
}
