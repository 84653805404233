.c-btn,
%c-btn {
	@extend %sans-serif-base;
	background-color: palette(purple);
	border-radius: 0;
	color: $c-white;
	display: inline-block;
	font-weight: $font-weight-bold;
	min-height: toRems(50px);
	padding: toRems(14px) $spacing-medium;
	position: relative;
	text-align: center;
	vertical-align: middle;

	@include breakpoint($bp-xsmall) {
		font-style: toRems($base-font-size);
		width: 100%;
		position: relative;
    left: 0;
	}

	@media print {
		padding: 0;
	}

	&:before {
		display: none;
	}

	&.is-loading {
		color: transparent;

		&:before {
			animation: spinner 1s infinite linear;
			border-color: rgba($c-white, 0.25) rgba($c-white, 0.25) rgba($c-white, 0.25) rgba($c-white, 0.75);
			border-radius: 100%;
			border-style: solid;
			border-width: 4px;
			content: "";
			display: block;
			height: toRems(30px);
			left: 50%;
			margin-left: toRems(-15px);
			margin-top: toRems(-15px);
			position: absolute;
			top: 50%;
			transition-duration: transform 0.3s $global-easing, opacity 0.3s $global-easing;
			width: toRems(30px);
			z-index: -1;
		}
	}

	&__inner {
		border-bottom: 1px solid transparent;
		position: relative;
		transition: all 0.5s $global-easing;
		vertical-align: middle;
		.recite-ele {
			font-size: toRems(12px);
		}

		&:before {
			@extend %i-chevron-right;
			font-size: toRems(13px);
			line-height: 1;
			opacity: 0;
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translate(-14px, -51%);
			transition: opacity 0.5s $global-easing, transform 0.5s $global-easing;
		}
	}

	svg {
		fill: $c-white;
		height: toRems(25px);
		margin-top: toRems(-13px);
		max-width: toRems(25px);
		position: absolute;
		right: 100%;
		top: 50%;
	}

	&:hover,
	html[data-focus-source="key"] > body &:focus {
		.c-btn__inner {
			border-color: rgba($c-white, 0.5);

			&:before {
				opacity: 1;
				transform: translate(-6px, -51%);
			}
		}

        // Prevent hover state on disabled button
        &:disabled {
            .c-btn__inner {
                border: none;

                &:before {
                    opacity: 0;
                }
            }
        }
	}

	&.is-disabled {
		opacity: 0.5;
		pointer-events: none;
	}

    &:disabled {
        opacity: 0.5;
    }

	// Green Button
	&--green {
		background-color: palette(green);
	}

	&--light-purple {
		background-color: palette(purple, light);
	}

	// White Button
	&--white {
		background-color: $c-white;
		color: palette(purple);

		&:hover,
		&:focus {
			.c-btn__inner {
				border-color: palette(purple, light);
			}
		}

		svg {
			fill: palette(purple);
		}
	}

	// Small Button
	&--small {
		font-size: toRems($base-font-size);
		min-height: toRems(45px);
		padding: toRems(12px) $spacing-medium;
	}

	// Medium Button
	&--medium {
		min-height: toRems(45px);
		padding: toRems(12px) $spacing-medium toRems(10px);
	}

	&--mid-large {
		min-height: toRems(60px);
		padding: toRems(19px) $spacing-medium;
	}

	// Large Button
	&--large {
		min-height: toRems(60px);
		padding: toRems(19px) $spacing-medium;

		@include breakpoint($bp-medium) {
			min-width: toRems(210px);
		}
	}

	// No horizontal padding button

	&--small-hor-padding {
		padding-left: $spacing-xsmall;
		padding-right: $spacing-xsmall;
	}

	// No min width Button
	&--no-min-width {
		@include breakpoint($bp-medium) {
			min-width: 0;
		}
	}

	&--underlined {
		.c-btn__inner {
			border-color: rgba($c-white, 0.5);
		}
	}

	// Reset hover state on icon states
	&--icon-left,
	&--icon-right {
		.c-btn__inner {
			&:before {
				display: none !important;
			}
		}
	}

	// Icon on the left
	&--icon-left {
		.c-btn__inner {
			margin-left: toRems(23px) !important;
		}

		svg {
			margin-right: $spacing-xsmall;
		}
	}

	// Icon on the right
	&--icon-right {
		.c-btn__inner {
			margin-right: toRems(23px);
		}

		svg {
			left: 100%;
			margin-left: $spacing-xsmall;
			right: auto;
		}
	}

	&--icon-small {
		svg {
			fill: $c-white;
			height: toRems(18px);
			margin-top: toRems(-8px);
			max-width: toRems(18px);
			position: absolute;
			right: 100%;
			top: 50%;
		}
	}

	&--full\@medium-max {
		@include breakpoint($bp-medium-max) {
			width: 100%;
		}
	}

	&--stack\@medium-max {

		@include breakpoint($bp-medium-max) {
			font-size: $font-xsmall;
			height: toRems(60px);
			padding: $spacing-xsmall;
			width: toRems(65px);
		}

		.c-btn__inner {

			@include breakpoint($bp-medium-max) {
				border: none;
				display: block;
				margin: 0 auto !important;

				&:before {
					display: none;
				}
			}
		}

		svg {

			@include breakpoint($bp-medium-max) {
				display: block;
				left: auto;
				margin: 0 auto toRems(3px);
				max-height: toRems(24px);
				position: relative;
				right: auto;
				top: auto;
				transform: none;
			}
		}
	}

	&--fixed {
		background-color: transparent;
		bottom: $spacing-small;
		display: none;
		left: 50%;
		min-height: 0;
		position: absolute;
		text-shadow: 0 3px 6px rgba($c-black,0.5), 0 3px 6px rgba($c-black,0.5);
		transform: translateX(-50%) translateZ(0);
		z-index: 500;

		@include breakpoint($bp-medium) {
			display: block;
		}

		svg {
			@include material-shadow(2);
			animation: up-down 2s infinite;
			background-color: palette(purple);
			border-radius: 100%;
			border: 2px solid $c-white;
			height: 30px;
			margin-top: -15px;
			max-height: none;
			max-width: none;
			padding: 6px;
			width: 30px;
		}
	}

	&--xmedium-flex-column-centred {
		@include breakpoint($bp-medium-max) {
			display: flex;
			align-items: center;
		}
	}
}

:lang(en) .c-btn {
	.c-btn__inner {
		.recite-ele {
			font-size: toRems(16px);
		} 
	}
}

.fui-btn-wrapper {
	text-align: center;
	button.fui-submit {
		padding: 1.1875rem 1.875rem;
		font-weight: 700;
		margin: 0 auto;
		font-size: 1rem;
		background-color: #5e56a5;
		border-radius: 0;
	}
}