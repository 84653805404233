.c-logos {
	&__title {
		@extend %sans-serif-mid-large;
		margin-bottom: $spacing-base;
		text-align: center;
	}

	&__list {
		font-size: 0;
		text-align: center;
	}

	&__logo {
		background: no-repeat center center scroll transparent;
		background-size: contain;
		display: inline-block;
		height: toRems(62.5px);
		margin: $spacing-xsmall;
		position: relative;
		vertical-align: middle;
		width: toRems(125px);

		@include breakpoint($bp-small) {
			padding: $spacing-small;
		}

		@include breakpoint($bp-medium) {
			margin: $spacing-medium;
		}

		@include breakpoint($bp-large) {
			margin: $spacing-base $spacing-large;
		}

		@include breakpoint($bp-xlarge) {
			margin: $spacing-base toRems(70px);
		}

		&:nth-child(4n+1) {
			@include breakpoint($bp-xlarge) {
				margin-left: 0;
			}
		}

		&:nth-child(4n+4) {
			@include breakpoint($bp-xlarge) {
				margin-right: 0;
			}
		}

		img {
			display: none;
		}

		a {
			&,
			&:link,
			&:visited {
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	+ #{&} {
		margin-top: toRems(70px);
	}


	&--large {
		.c-logos__title {
			@extend %sans-serif-large;
			margin-bottom: $spacing-xsmall;
		}

		.c-logos__logo {
			height: toRems(100px);
			width: toRems(200px);

			@include breakpoint($bp-medium) {
				margin: $spacing-medium;
			}

			@include breakpoint($bp-xlarge) {
				margin: $spacing-large;
			}

			&:nth-child(4n+1),
			&:nth-child(4n+4) {
				@include breakpoint($bp-xlarge) {
					margin-left: $spacing-large;
					margin-right: $spacing-large;
				}
			}
		}
	}

	&--xlarge {
		.c-logos__title {
			@extend %sans-serif-large;
			margin-bottom: $spacing-xsmall;
		}

		.c-logos__logo {
			height: toRems(120px);
			width: toRems(240px);

			@include breakpoint($bp-small) {
				height: toRems(160px);
				width: toRems(320px);
			}

			@include breakpoint($bp-medium) {
				margin: $spacing-base;
			}

			@include breakpoint($bp-xlarge) {
				margin: $spacing-large;
			}

			&:nth-child(4n+1),
			&:nth-child(4n+4) {
				@include breakpoint($bp-xlarge) {
					margin-left: $spacing-large;
					margin-right: $spacing-large;
				}
			}
		}
	}

	&--small {
		.c-logos__title {
			@extend %sans-serif-base;
			margin-bottom: 0;
		}

		.c-logos__logo {
			height: toRems(47.5px);
			width: toRems(95px);

			&:nth-child(4n+1),
			&:nth-child(4n+4) {
				@include breakpoint($bp-xlarge) {
					margin-left: toRems(70px);
					margin-right: toRems(70px);
				}
			}
		}
	}
}
