.c-tile {
	background-color: palette(purple);
	color: $c-white;
	display: block;
	padding: $spacing-base $spacing-base $spacing-large;

	&__title {
		@extend %sans-serif-mid-large;
		font-size: toRems(24px);
		line-height: 1.2;
		margin-bottom: $spacing-base;
	}

	&__subtitle {
		@extend %sans-serif-base;

		@include breakpoint($bp-xsmall) {
			font-size: $font-small;
		}
	}

	&--date {
		padding: $spacing-small;
		text-align: center;

		.c-tile__title,
		.c-tile__subtitle {
			display: block;
		}

		.c-tile__title {
			margin-bottom: 0;

			@include breakpoint($bp-medium) {
				font-size: $font-size-3;
			}
		}
	}

	&--date-large {
		@extend .c-tile--date;
		padding: $spacing-medium $spacing-small;
	}
}
