@font-face {
	font-family: "Kaputa Sinhalese";
	src: url("/fonts/kaputa-sinhalese.eot"); 
	src: url("/fonts/kaputa-sinhalese.eot?#iefix") format("embedded-opentype"),
		url("/fonts/kaputa-sinhalese.woff") format("woff"),
		url("/fonts/kaputa-sinhalese.ttf") format("truetype"),
		url("/fonts/kaputa-sinhalese.svg#kaputa-sinhalese") format("svg");
	font-style:  normal;
    font-weight:  normal;
}

@font-face {
	font-family: "Tharmini Plain Tamil";
	src: url("/fonts/tharmini-plain-tamil.eot");
	src: url("/fonts/tharmini-plain-tamil.eot?#iefix") format("embedded-opentype"),
		url("/fonts/tharmini-plain-tamil.woff") format("woff"),
		url("/fonts/tharmini-plain-tamil.ttf") format("truetype"),
		url("/fonts/tharmini-plain-tamil.svg#tharmini-plain-tamil") format("svg");
	font-style:  normal;
    font-weight:  normal;
}
