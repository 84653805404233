.c-hero {
	position: relative;
	margin-bottom: $spacing-base;

	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-medium;
		min-height: toRems(500px);
	}

	@include breakpoint($bp-large) {
		margin-bottom: toRems(40px);
	}

	&__bg-img {
		@extend %c-img-frame;
		overflow: hidden;

		@include breakpoint($bp-medium) {
			position: absolute !important;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		}
	}

	&__img {
		background-position: right top;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		transition: opacity 0.5s $global-easing, transform 2s $global-easing;
		z-index: 1;

		@include breakpoint($bp-medium) {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		img {
			width: 100%;
		}
	}

	&__title-small {
		color: $c-white;
		margin-bottom: $spacing-xsmall;

		&:last-child {
			margin-bottom: 0;
		}

		a,
		.c-hero__label {
			border-bottom: 1px solid palette(purple, light);
			color: inherit;
			transition: all 0.5s $global-easing;

			&:hover {
				border-color: inherit;
			}
		}
	}

	&__title {
		@extend %sans-serif-xlarge;
		color: $c-white;
		margin-bottom: $spacing-xsmall;

		&:last-child {
			margin-bottom: 0;
		}

		a,
		.c-hero__label {
			border-bottom: toEms(1px) solid transparent;
			color: inherit;
			display: inline;
			transition: all 0.5s $global-easing;

			&:hover {
				border-color: $c-white;
			}
		}
	}

	&__body {
		margin-top: $spacing-small;
	}

	&__link {
		&:hover {
			.c-hero__label {
				border-color: inherit;
			}
		}
	}

	&__content {
		background-color: palette(purple);
		color: $c-white;
		margin: -$spacing-base $spacing-base 0;
		padding: $spacing-medium;
		position: relative;
		z-index: 10;

		@media print {
			padding: 0;
		}

		@include breakpoint($bp-medium) {
			bottom: $spacing-base;
			left: $spacing-base;
			margin: 0;
			position: absolute;
			width: toRems(320px);
		}

		@include breakpoint($bp-xsmall) {
			padding: $spacing-small $spacing-small $spacing-base;
		}
	}

	&__action {
		bottom: $spacing-base;
		font-size: 0;
		line-height: 1;
		position: absolute;
		right: $spacing-base;

		@include breakpoint($bp-medium) {
			top: toRems(68px);
			bottom: auto;
		}
	}


	/**
	 * Alter hero content area to stick to bottom of slideshow. If slide
	 * has a longer title that breaks onto 2 lines, it will overlap the
	 * hero image slightly.
	 */

	&--slideshow {
		padding-bottom: toRems(130px);

		.c-hero__content {
			bottom: 0;
			left: 0;
			margin-left: 0;
			margin-right: 0;
			min-height: toRems(130px);
			padding-bottom: $spacing-base;
			position: absolute;
			width: 100%;

			@include breakpoint($bp-medium) {
				bottom: $spacing-base;
				left: $spacing-base;
				margin: 0;
				min-height: 0;
				position: absolute;
				width: toRems(320px);
			}
		}

		.c-hero__title {
			margin-bottom: $spacing-small;

			@include breakpoint($bp-medium) {
				margin-bottom: $spacing-xsmall;
			}
		}

		.c-hero__title-small {
			min-height: toRems(50px); // Same height as slideshow buttons
			padding-right: toRems(140px);

			@include breakpoint($bp-medium) {
				padding-right: 0;
			}
		}

		.c-hero__action {
			@include breakpoint($bp-medium) {
				top: toRems(25px);
			}
		}
	}

	&--wide-content {
		.c-hero__content {
			@include breakpoint($bp-medium) {
				width: toRems(540px);
			}
		}
	}

	&--breadcrumb {
		.c-hero__content {
			padding-right: toRems(90px);

			@include breakpoint($bp-medium) {
				width: toRems(400px);
			}
		}

		&.c-hero--wide-content {
			.c-hero__content {
				padding-right: toRems(90px);

				@include breakpoint($bp-medium) {
					width: toRems(650px);
				}
			}
		}
	}

	&--small {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-medium;
			min-height: toRems(300px);
		}
	}
}
