.u-hide {
	&#{&} {
		display: none;
	}
}

/**
* Show/hide @bp-xlarge
*/

.u-show\@xlarge {
	&#{&} {
		display: none;

		@include breakpoint($bp-xlarge) {
			display: block;
		}
	}
}

.u-hide\@xlarge {
	&#{&} {
		@include breakpoint($bp-xlarge) {
			display: none;
		}
	}
}

/**
* Show/hide @bp-large
*/

.u-show\@large {
	&#{&} {
		display: none;

		@include breakpoint($bp-large) {
			display: block;
		}
	}
}

.u-hide\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			display: none;
		}
	}
}

/**
* Show/hide @bp-medium
*/

.u-show\@medium {
	&#{&} {
		display: none;

		@include breakpoint($bp-medium) {
			display: block;
		}
	}
}

.u-hide\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			display: none;
		}
	}
}

/**
* Show @bp-medium (Inline block)
*/

.u-show-inline\@medium {
	&#{&} {
		display: none;

		@include breakpoint($bp-medium) {
			display: inline-block;
		}
	}
}

/**
* Show @bp-large (Inline block)
*/

.u-show-inline\@xlarge {
	&#{&} {
		display: none;

		@include breakpoint($bp-xlarge) {
			display: inline-block;
		}
	}
}

/**
* Show/hide @bp-small
*/

.u-show\@small {
	&#{&} {
		display: none;

		@include breakpoint($bp-small) {
			display: block;
		}
	}
}

.u-hide\@small {
	&#{&} {
		@include breakpoint($bp-small) {
			display: none;
		}
	}
}


/**
* Show/hide @bp-medium-max
*/

.u-show\@medium-max {
	&#{&} {
		display: none;

		@include breakpoint($bp-medium-max) {
			display: block;
		}
	}
}

.u-hide\@medium-max {
	&#{&} {
		@include breakpoint($bp-medium-max) {
			display: none;
		}
	}
}

/**
* Show/hide @touchevents
*/

.u-hide\@touchevents {
	&#{&} {
		.touchevents & {
			display: none;
		}
	}
}

.u-show\@touchevents {
	&#{&} {
		.touchevents & {
			display: block;
		}
	}
}
